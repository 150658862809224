import { Bar, Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
);
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const LiveGraph = ({ demandLimit, instantPower, graphData,demandLimitValue,selectedChargers }: any) => {
    // Transform graphData into the required format
    const transformGraphData = () => {
        if (!graphData || !selectedChargers?.length) return [];
        
        const colors = ['#EB407A', '#B2DF8A', '#B4431F', '#A6CEE3'];
        
        // Filter and transform only selected chargers
        const chargerLines = graphData
            .filter(chargerData => selectedChargers.includes(chargerData?.charger))
            .map((chargerData, index) => ({
                id: index + 1,
                label:chargerData?.charger ? `Charger ${chargerData?.charger.slice(-4)}` : `Charger `,
                data:chargerData?.data?.length > 0 ? chargerData?.data?.map(point => point.instantValue) : [],
                fill: false,
                borderColor: colors[index % colors.length],
                tension: 0.1,
                backgroundColor: [colors[index % colors.length]],
            }));

        const datasets = [...chargerLines];
        
        // Only add total power line if there is data and instantPower is true
        if (instantPower && chargerLines?.some(line => line?.data?.length > 0)) {
            datasets.push({
                id: 'total',
                label: 'Total Power',
                data: calculateTotalPower(),
                fill: false,
                borderColor: '#4318FF',
                tension: 0.1,
                backgroundColor: '#4318FF',
                borderWidth: 3,
                type: 'line'
            });
        }

        // Only add demand limit line if there is data and demandLimit is true
        if (demandLimit && chargerLines?.some(line => line.data?.length > 0)) {
            datasets.push({
                id: 'demandLimit',
                label: 'Demand Limit',
                data: Array(graphData[0]?.data?.length).fill(demandLimitValue || 0),
                fill: false,
                borderColor: '#F79009',
                tension: 0.1,
                backgroundColor: '#F79009',
                borderWidth: 2,
                type: 'line'
            });
        }

        return datasets;
    };

    // New function to calculate total power for each timestamp
  // New function to calculate total power for each timestamp based on selected chargers
const calculateTotalPower = () => {
    if (!graphData || !selectedChargers?.length) return [];

    // Get the first valid charger data to determine the number of time points
    const firstChargerData = graphData?.find(data => data?.data?.length > 0);
    if (!firstChargerData?.data) return [];

    // Create an array for each time point
    return firstChargerData.data.map((_, timeIndex) => {
        // Sum the power values from all selected chargers at this time point
        const total = selectedChargers.reduce((sum, chargerId) => {
            const chargerData = graphData.find(data => data.charger === chargerId);
            if (!chargerData?.data?.[timeIndex]) return sum;
            return sum + (Number(chargerData.data[timeIndex].instantValue) || 0);
        }, 0);
        
        return Number(total.toFixed(2));
    });
};

    const [data, setData] = useState(transformGraphData());

    // Update data when graphData or other relevant props change
    useEffect(() => {
        setData(transformGraphData());
        getLabels()
    }, [graphData, demandLimit, instantPower, demandLimitValue,selectedChargers]);

   
    const getLabels = () => {
        if (!graphData?.[0]?.data) return [];
        return graphData[0]?.data.map((point: any) => {
            // const date = new Date(point.timeInterval + 'Z');
        
           const date = dayjs.utc(point.timeInterval).toDate();
            return date.toLocaleTimeString([], { 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: false
            });
        });
    };
    const getData = () => {
        const labels: any = getLabels();
        const datasets: any = data;
        return {
            labels: labels,
            datasets: datasets
        };
    };

    const toptions: any = {
        chart: {
            type: 'bar',
        },
        elements: {
            point: {
                radius: 0
            }
        },
        interaction: {
            mode: 'nearest'
        },
        hover: {
            mode: 'nearest',
            intersect: true,
        },
        responsive: true,
        stacked: false,
        colors: ['#85D8DD', '#427ae3', '#3dc7d1', '#faa93e'],
        fill: {
            colors: ['#85D8DD', '#427ae3', '#3dc7d1', '#faa93e']
        },
        dataLabels: {
            enabled: false,
        },

        plugins: {
            legend: {
                display: false,
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
            customLines: {
                x: {
                    afterDraw: (chart: { tooltip?: any; scales?: any; ctx?: any }) => {
                        // eslint-disable-next-line no-underscore-dangle
                        if (chart.tooltip._active && chart.tooltip._active.length) {
                            // find coordinates of tooltip
                            const activePoint = chart.tooltip._active[0];
                            const { ctx } = chart;
                            const { x } = activePoint.element;
                            const topY = chart.scales.y.top;
                            const bottomY = chart.scales.y.bottom;

                            // draw vertical line
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(x, topY);
                            ctx.lineTo(x, bottomY);
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = '#1C2128';
                            ctx.stroke();
                            ctx.restore();
                        }
                    },
                },
                y: {
                    afterDraw: (chart: { tooltip?: any; scales?: any; ctx?: any }) => {
                        // eslint-disable-next-line no-underscore-dangle
                        if (chart.tooltip._active && chart.tooltip._active.length) {
                            // find coordinates of tooltip
                            const activePoint = chart.tooltip._active[0];
                            const { ctx } = chart;
                            const { x } = activePoint.element;
                            const topY = chart.scales.y.top;
                            const bottomY = chart.scales.y.bottom;

                            // draw vertical line
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(x, topY);
                            ctx.lineTo(x, bottomY);
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = '#1C2128';
                            ctx.stroke();
                            ctx.restore();
                        }
                    },
                },
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        scales: {
            y: {
                min: 0,
                position: 'left',
                title: {
                    display: true,
                    text: 'Demand (KW)',
                    color: 'black',
                    fontWeight: 700,
                    padding: 5
                },
                grid: {
                    display: false,
                },
                ticks: {
                    // stepSize: 1000// <----- This prop sets the stepSize
                }
            },
            x: {
                barPercentage: 0.5,
                categorySpacing: 0,
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 24,
                    maxRotation: 45,
                    minRotation: 45,
                 
                }
            },
        },
        maintainAspectRatio: false,
        tooltip: {
            y: {
                formatter: function (context: any) {
                    if (typeof context === 'number') {
                        return context;
                    } else {
                        return context[1] - context[0];
                    }
                }
            }
        },
    };

    return (
        <div className={styles.graphContainer} data-cy='live-graph-demand-view'>
            {graphData && graphData.length > 0 ? (
                <Chart 
                    type='line' 
                    data={getData()} 
                    style={{ width: '100%' }} 
                    options={{...toptions}} 
                />
            ) : (
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '300px',
                    fontSize: '16px',
                    color: '#666'
                }}>
                    No live session data available
                </div>
            )}
        </div>
    );
};


import { useEffect, useState } from 'react';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import SiteArea from './siteArea';
import {
  convertToBase64,
  withContext,
  withUseFormHook,
} from '@/common/utils/withContext';
import {
  ISiteAreaFormStateModel,
  ISiteAreaFormPropsModel,
  SiteAreaFormModel,
  RegistrationToken,
} from '@/modules/organization/shared/models/siteArea';
import { formSchema } from '../../../../shared/schemas/siteAreaFormSchema';
import { UseFormReturn } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import {
  addSiteArea,
  createRegistrationToken,
  getRegistrationTokens,
  getSiteAreaById,
  getSiteAreaImage,
  updateSiteArea,
} from '@/services/organizations';
import { DefaultPageSize, HTTPError, phaseAssignmentToGridMapSinglePhased, phaseAssignmentToGridMapThreePhased } from '@/common/constants/constants';
import { EvesModal } from '@/common/components/molecules/modal/modal';
import Limits from './limits';
import Roaming from './roaming';
import { NotifyType } from '@/common/utils/notificationService';
import { Cloud } from '@mui/icons-material';
import { connect } from 'react-redux';
import { Dayjs } from '@/common/utils/dayjsUtil';
import { useTranslation } from 'react-i18next';
import { isActive, isAdmin } from '@/common/utils/utils';
import { TenantComponents } from '@/common/enums/enums';
import PowerLimits from '@/modules/gridify/shared/components/PowerLimit';
export enum SubSiteAreaAction {
  UPDATE = 'update',
  ATTACH = 'attach',
  CLEAR = 'clear',
  FORCE_SMART_CHARGING = 'force_smart_charging',
}

const findAmpValue = (maximumPower: any, numberOfPhases: any, voltage: any) => {
  let maxPowerAmps = 0;
  maxPowerAmps = Math.floor(maximumPower / voltage);
  let maxPowerPerPhase = 0;
  maxPowerPerPhase = Math.floor(maximumPower / voltage / numberOfPhases);
  return { maxPowerAmps, maxPowerPerPhase };
};

function SiteAreaForm(props: ISiteAreaFormPropsModel) {
  const siteAreaForm = props.formService as UseFormReturn<
    SiteAreaFormModel,
    any
  >;
  const isFormDirty = siteAreaForm.formState.isDirty;
  const isSaveEnabled = siteAreaForm.formState.isValid && isFormDirty;
  const siteAreaName = siteAreaForm.watch('name');
  const subSiteAreaActions: SubSiteAreaAction[] = [];
  const params = { TenantID: props.userInfo.tenantId };
  const isUserAdmin = isAdmin();
  const { t: translate } = useTranslation();
  let phaseAssignment: any = phaseAssignmentToGridMapThreePhased;
  const [state, setStateData] = useState({
    selectedTab: '1',
    pageLimit: DefaultPageSize,
    isDisabled: false,
    canUpdateSiteAreaLimits:false
  } as ISiteAreaFormStateModel);
 const [phaseData , setPhaseData] = useState<any>();
  const notify: NotifyType = {
    message: '',
    type: 'success',
  };

  useEffect(() => {
    loadSiteArea();
  }, []);

  const loadSiteArea = async () => {
    if (props.siteAreaId) {
      getSiteArea();
    }
  };
  const getTotalSum = (array:any, property:any) => {
    const values = array?.map(item => item?.[property]);
    return values?.reduce((accumulator, currentValue) => accumulator + (currentValue || 0), 0);
  };
  const { refetch: getSiteArea } = useQuery(
    ['data', props.siteAreaId],
    () => {
      const siteAreaParams = {
        WithSite: true,
        WithParentSiteArea: true,
      };
      return getSiteAreaById(props.siteAreaId, siteAreaParams);
    },
    {
      enabled: false,
      onSuccess: (response: any) => {
        setStateData((currentData) => {
          return {
            ...currentData,
            isDisabled: !response?.canUpdate,
            canUpdateSiteAreaLimits: response.canUpdateSiteAreaLimits
          };
        });
        loadRegistrationToken();
        const address = response.address;
        if (address?.coordinates.length === 0) {
          address.coordinates = ['', ''];
        }
        // find maximumTotalPowerAmps AND maximumPowerAmpsPerPhase
        const ampValues = findAmpValue(
          response.maximumPower,
          response.numberOfPhases,
          response.voltage
        );
        const totalInstallPower = getTotalSum(response?.chargingStations, 'maximumPower');
        
        // Using the function to get the sum of maximumAmps
        const totalInstallAmps = getTotalSum(response?.chargingStations, 'maximumAmps');
     
        if (response?.numberOfPhases === 1) {
          phaseAssignment = phaseAssignmentToGridMapSinglePhased;
        } else {
          phaseAssignment = phaseAssignmentToGridMapThreePhased;
        }
       
      
        siteAreaForm.reset({
          id: response.id,
          name: response.name,
          issuer: response.issuer,
          siteID: response.siteID,
          image: '',
          address: response.address,
          site: response.site?.name,
          accessControl: response.accessControl,
          maximumPower: response.maximumPower,
          maximumPowerAmpsPerPhase: ampValues.maxPowerPerPhase,
          maximumTotalPowerAmps: ampValues.maxPowerAmps,
          numberOfPhases: response.numberOfPhases,
          phaseAssignment:response?.phaseAssignment,
          smartCharging: response.smartCharging,
          tariffID: response.tariffID,
          voltage: response.voltage,
          registrationToken: response.registrationToken,
          parentSiteAreaID: response?.parentSiteAreaID,
          parentSiteArea: response?.parentSiteArea?.name ?? '',
          public: response?.site?.public,
          subStationID: response.subStationID ?? 'Default',
          transformerID: response.transformerID ?? 'Default',
          feederID: response.feederID ?? 'Default',
          ampPerPhase: Number(response?.ampPerPhase?.toFixed(2)),
          totalAmpAllPhases: Number(response?.totalAmpAllPhases?.toFixed(2)),
          rating: response?.rating,
          installedChargers: response?.chargingStations?.length,
          totalInstallAmp:totalInstallPower,
          totalInstallKw:totalInstallAmps
        });
        getImage();
      },
    }
  );

  const { refetch: getImage } = useQuery(
    'image',
    () => {
      return getSiteAreaImage(props.siteAreaId, params);
    },
    {
      enabled: false,
      onSuccess: (data: any) => {
        convertToBase64(data, siteAreaForm, 'image');
      },
    }
  );

  const { refetch: loadRegistrationToken } = useQuery(
    ['token'],
    () => {
      const registrationParams = {
        SiteAreaID: props.siteAreaId,
        Limit: state.pageLimit,
      };
      return getRegistrationTokens(registrationParams);
    },
    {
      enabled: false,
      onSuccess: (dataResult: any) => {
        if (dataResult && dataResult.result) {
          for (const registrationToken of dataResult.result) {
            if (isRegistrationTokenValid(registrationToken)) {
              siteAreaForm.setValue('registrationToken', registrationToken);
              break;
            }
          }
        }
      },
    }
  );

  const isRegistrationTokenValid = (
    registrationToken: RegistrationToken
  ): boolean => {
    const now = Dayjs();
    return (registrationToken.expirationDate &&
      now.isBefore(registrationToken.expirationDate) &&
      (!registrationToken.revocationDate ||
        now.isBefore(registrationToken.revocationDate))) as boolean;
  };

  const { mutate: saveSiteArea } = useMutation(
    (siteAreaFormData) => addSiteArea(siteAreaFormData),
    {
      onSuccess: (data: any) => {
        if (data.status === 'Success') {
          props.onFormClose();
          props.resetSiteAreaForm('');
          notify.message = `${translate('site_areas.create_success').replace(
            /{{siteAreaName}}/,
            `'${siteAreaName}'`
          )}`;
          notify.type = 'success';
          props.notificationService?.notify(notify);
          props.fetchAllSiteAreas();
        } else if (data.status === 404) {
          notify.message = `${translate(
            'site_areas.site_area_does_not_exist'
          )}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
          props.onFormClose();
          props.resetSiteAreaForm('');
          props.notificationService?.notify(notify);
        } else {
          handleHttpTreeError(data, `${translate('site_areas.create_error')}`);
        }
      },
    }
  );

  const { mutate: editSiteArea } = useMutation(
    (siteAreaFormData) => updateSiteArea(siteAreaFormData, props.siteAreaId),
    {
      onSuccess: (data: any) => {
        if (data.status === 'Success') {
          props.onFormClose();
          props.resetSiteAreaForm('');
          notify.message = `${translate('site_areas.update_success').replace(
            /{{siteAreaName}}/,
            `'${siteAreaName}'`
          )}`;
          notify.type = 'success';
          props.notificationService?.notify(notify);
          props.fetchAllSiteAreas();
        } else if (data.status === 404) {
          notify.message = `${translate(
            'site_areas.site_area_does_not_exist'
          )}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
          props.onFormClose();
          props.resetSiteAreaForm('');
          props.notificationService?.notify(notify);
        } else {
          handleHttpTreeError(data, `${translate('site_areas.update_error')}`);
        }
      },
    }
  );

  const onFormSubmit = (formData: SiteAreaFormModel) => {
    const getFormValues = siteAreaForm.getValues();
    const dataToSend: any = { ...formData };
    if (
      dataToSend.address.coordinates.length > 0 &&
      dataToSend.address.coordinates[0] === ''
    ) {
      dataToSend.address.coordinates = [];
    }
    const parentSiteArea = getFormValues.parentSiteArea;
    const parentSiteAreaID = getFormValues.parentSiteAreaID;
    dataToSend.parentSiteArea = parentSiteArea ? parentSiteArea : null;
    dataToSend.parentSiteAreaID = parentSiteAreaID ? parentSiteAreaID : null;
    if (props.siteAreaId) {
      editSiteArea(dataToSend);
    } else {
      saveSiteArea(dataToSend);
    }
  };

  const handleHttpTreeError = (error: any, defaultMessage: string) => {
    const notify: NotifyType = {
      message: '',
      type: 'success',
    };
    switch (error.status) {
      case HTTPError.SITE_AREA_TREE_ERROR: {
        const translatedText = `${translate(
          'site_areas.site_area_tree_error'
        )}`.split(',');
        const description = translatedText
          .map((part) => `<p >${part.trim()}</p>`)
          .join('');
        props.dialogService?.showConfirm({
          title: `${translate('site_areas.site_area_hierarchy_error_title')}`,
          description: [
            <div
              key=''
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>,
          ],
          confirmType: 'OK',
        });
        break;
      }
      case HTTPError.SITE_AREA_TREE_ERROR_SITE: {
        const translatedText = `${translate(
          'site_areas.site_area_tree_error_site'
        )}`.split(',');
        const description = translatedText
          .map((part) => `<p >${part.trim()}</p>`)
          .join('');
        props.dialogService?.showConfirm(
          {
            title: `${translate('site_areas.site_area_hierarchy_error_title')}`,
            description: [
              <div
                key=''
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>,
            ],
            confirmType: 'SUB_SITEAREA_ACTION',
          },
          (result: any) => {
            let subSiteAreaAction = '';
            switch (result) {
              case 'ATTACH':
                subSiteAreaAction = SubSiteAreaAction.ATTACH;
                break;
              case 'UPDATE':
                subSiteAreaAction = SubSiteAreaAction.UPDATE;
                break;
              case 'CLEAR':
                subSiteAreaAction = SubSiteAreaAction.CLEAR;
                break;
            }
            if (subSiteAreaAction) {
              siteAreaForm.setValue('subSiteAreasAction', subSiteAreaAction);
              siteAreaForm.handleSubmit(onFormSubmit)();
            }
          }
        );
        break;
      }
      case HTTPError.SITE_AREA_TREE_ERROR_SMART_CHARGING: {
        const translatedText = `${translate(
          'site_areas.site_area_tree_error_smart_charging'
        )}`.split(',');
        const description = translatedText
          .map((part) => `<p >${part.trim()}</p>`)
          .join('');
        props.dialogService?.showConfirm(
          {
            title: `${translate('site_areas.site_area_hierarchy_error_title')}`,
            description: [
              <div
                key=''
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>,
            ],
            confirmType: 'YES_NO',
          },
          (result: any) => {
            if (result === 'YES') {
              siteAreaForm.setValue(
                'subSiteAreasAction',
                SubSiteAreaAction.FORCE_SMART_CHARGING
              );
              siteAreaForm.handleSubmit(onFormSubmit)();
            }
          }
        );
        break;
      }
      case HTTPError.SITE_AREA_TREE_ERROR_SMART_NBR_PHASES: {
        const translatedText = `${translate(
          'site_areas.site_area_tree_error_number_of_phases'
        )}`.split(',');
        const description = translatedText
          .map((part) => `<p >${part.trim()}</p>`)
          .join('');
        props.dialogService?.showConfirm({
          title: `${translate('site_areas.site_area_hierarchy_error_title')}`,
          description: [
            <div
              key=''
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>,
          ],
          confirmType: 'OK',
        });
        break;
      }
      case HTTPError.SITE_AREA_TREE_ERROR_MULTIPLE_ACTIONS_NOT_SUPPORTED: {
        const translatedText = `${translate(
          'site_areas.site_area_tree_error_multiple_actions_not_supported'
        )}`.split(',');
        const description = translatedText
          .map((part) => `<p >${part.trim()}</p>`)
          .join('');
        props.dialogService?.showConfirm({
          title: `${translate('site_areas.site_area_hierarchy_error_title')}`,
          description: [
            <div
              key=''
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>,
          ],
          confirmType: 'OK',
        });
        subSiteAreaActions.length = 0;
        break;
      }
      case HTTPError.SITE_AREA_TREE_ERROR_VOLTAGE: {
        const translatedText = `${translate(
          'site_areas.site_area_tree_error_voltage'
        )}`.split(',');
        const description = translatedText
          .map((part) => `<p >${part.trim()}</p>`)
          .join('');
        props.dialogService?.showConfirm({
          title: `${translate('site_areas.site_area_hierarchy_error_title')}`,
          description: [
            <div
              key=''
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>,
          ],
          confirmType: 'OK',
        });
        break;
      }
      default:
        notify.message = defaultMessage;
        notify.type = 'error';
        props.notificationService?.notify(notify);
    }
  };

  const onHandelClose = () => {
    if (!!Object.keys(siteAreaForm.formState?.dirtyFields).length === true) {
      //based on the formValidity confirmation Dialogtype changes
      if (siteAreaForm.formState.isValid) {
        props.dialogService?.showConfirm(
          {
            confirmType: 'DIRTY_CHANGE_CLOSE',
          },
          (result) => {
            if (result === 'SAVE') {
              siteAreaForm.handleSubmit(onFormSubmit)();
            } else if (result === 'CLOSE') {
              props.onFormClose();
              props.resetSiteAreaForm('');
            }
          }
        );
      } else {
        props.dialogService?.showConfirm(
          {
            confirmType: 'INVALID_CHANGE_CLOSE',
          },
          (result: string) => {
            if (result === 'CLOSE') {
              props.onFormClose();
              props.resetSiteAreaForm('');
            }
          }
        );
      }
    } else {
      props.onFormClose();
      props.resetSiteAreaForm('');
    }
  };

  const { mutate: createRegToken } = useMutation(
    (tokenParams) => createRegistrationToken(tokenParams),
    {
      onSuccess: (res: any) => {
        if (res.status === 'Success') {
          props.notificationService?.notify({
            message: `${translate(
              'chargers.connections.registration_token_creation_success'
            )}`,
            type: 'success',
          });
          loadRegistrationToken();
        }
      },
    }
  );

  const createRegistrationTokens = () => {
    const siteAreaData = siteAreaForm.getValues();
    const tokenParams: any = {
      description: `${translate(
        'chargers.connections.registration_token_site_area_name'
      ).replace(/{{siteAreaName}}/, `'${siteAreaData.name}'`)}`,
      siteAreaID: siteAreaData.id,
    };
    createRegToken(tokenParams);
  };

  const onConnectToCharging = () => {
    props.dialogService?.showConfirm(
      {
        title: `${translate(
          'chargers.connections.registration_token_creation_title'
        )}`,
        description: `${translate(
          'chargers.connections.registration_token_creation_confirm'
        )}`,
        confirmType: 'YES_NO',
      },
      (result: any) => {
        if (result === 'YES') {
          createRegistrationTokens();
        }
      }
    );
  };

  const isOCPIActive = isActive(TenantComponents.OCPI);

  const tabsConfig = [
    {
      name: `${translate('site_areas.title')} - ${siteAreaName}`,
      icon: <AccountBoxOutlinedIcon />,
      component: (
        <SiteArea
          siteAreaForm={siteAreaForm}
          pageLimit={state.pageLimit}
          loadRegistrationToken={loadRegistrationToken}
          onConnectToCharging={onConnectToCharging}
          isDisabled={state.isDisabled}
        />
      ),
      visible: true,
    },
    {
      name: `${translate('site_areas.limits')}`,
      icon: <AccountBoxOutlinedIcon />,
      component: (
        <PowerLimits
          siteAreaForm={siteAreaForm as any}
          isDisabled={state.isDisabled}
        />
      ),
      visible: state.canUpdateSiteAreaLimits,
    },
    {
      name: `${translate('settings.tabs.ocpi')}`,
      icon: <Cloud />,
      component: (
        <Roaming siteAreaForm={siteAreaForm} isDisabled={state.isDisabled} />
      ),
      visible: isOCPIActive,
    },
  ];

  return (
    <>
      <EvesModal
        maxHeight={'773px'}
        maxWidth={'950px'}
        isOpen={props.openSiteArea}
        onHandleSave={siteAreaForm.handleSubmit(onFormSubmit)}
        onHandleClose={onHandelClose}
        modalType='tabs'
        isSaveDisabled={
          !(
            isSaveEnabled &&
            !!Object.keys(siteAreaForm.formState?.dirtyFields).length === true
          )
        }
        modalTabSelected={state.selectedTab}
        tabsConfig={tabsConfig}
        isSaveHidden={
          state.isDisabled || (props.isPowerLimit ? false : !props.canCreate)
        }
        scrollButtons={'auto'}
      ></EvesModal>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  userInfo: state.userContext.userInfo,
});

export default connect(mapStateToProps)(
  withContext(
    withUseFormHook(SiteAreaForm, {
      schema: formSchema,
      defaultValues: { ...new SiteAreaFormModel() },
    })
  )
);

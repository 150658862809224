const LIVE = 'Live';
const MONTH = 'Month';
const YEAR = 'Year';

const tabs = [
  { name: LIVE, id: 0, value: 'Live' },
  { name: MONTH, id: 1, value: 'Month' },
  { name: YEAR, id: 2, value: 'Year' },
];

const powertabs = [
  { name: 'Demand (KW)', id: 0, value: 'demand' },
  { name: 'Load Balancing (A)', id: 1, value: 'balancing' },
];

export { tabs, LIVE, YEAR, MONTH, powertabs };

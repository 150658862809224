import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import ChargingUnitSelectorComponent from '@/common/components/chargingUnitSelectorComponent';
import {
  DEFAULT_CURRENCY_CODE,
  GraphColor,
} from '@/common/constants/constants';
import {
  ConsumptionChartAxis,
  ConsumptionChartDatasetOrder,
  ConsumptionUnit,
} from '@/common/enums/enums';
import { Formatter } from '@/common/utils/formatHelper';
import { disableLoaderForOnce } from '@/common/utils/loadingService';
import {
  FormatLineColor,
  FormateNumber,
  getCurrencyCode,
  handleCanvasNoData,
  toRgba,
} from '@/common/utils/utils';
import {
  getConnectorAllConsumption,
  getConnectorConsumption,
} from '@/services/chargingStations';
import { ButtonGroup, FormControlLabel, Stack } from '@mui/material';
import { Box } from '@mui/system';
import styles from './chart.module.scss';
import {
  ChartData,
  ChartDataset,
  ChartOptions,
  Color,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Chart as ChartJS } from 'chart.js/auto';
//import { exportSessionChart, exportSessionExcel } from '@/services/session';
import { exportChartInExcel, exportChartInCSV } from './exportChart';
import { EvesButton } from '../../atoms/button/button';
import { ExpandMore } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

let chart: any = null;
let rawDatasets: any;
let firstLabel: any;
let selectedUnit = ConsumptionUnit.KILOWATT;

// eslint-disable-next-line react/display-name
const ConsumptionChart = forwardRef((props: any, ref: any) => {
  const { t: translate } = useTranslation();

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    TimeScale,
    LinearScale,
    LineController,
    PointElement,
    LineElement,
    Filler,
    {
      id: 'nodatahandle',
      afterDraw: (chart) => handleCanvasNoData(chart, translate),
    }
  );

  const currentTransactionID = props.currentTransactionID || props.id;

  const currencyCode = getCurrencyCode();
  let currencyUnit: string =
    getSymbolFromCurrency(currencyCode || DEFAULT_CURRENCY_CODE) ||
    currencyCode;
  const [chartData, setchartData] = useState<ChartData>({
    datasets: [],
    labels: [],
  });
  const [endDate, setEndDate] = useState<any[]>([]);
  const [dataAvailable, setDataAvailability] = useState(false);
  const location = useLocation();
  const [isfullResolutionChart, setIsfullResolutionChart] = useState(false);
  const [exportCharger, setExportCharger] = useState(false);
  let visibleLines: any[] = [];
  const chartStyles = {
    container: {
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingBottom: '15px',
    } as CSSProperties,
    canvas: {
      width: '99%',
      height: '360px',
      position: 'relative',
    } as CSSProperties,
    noDataAvailable: {
      height: '200px',
      textAlign: 'center',
      marginTop: '100px',
    } as CSSProperties,
  };
  const gridDisplay = {
    [ConsumptionChartAxis.POWER]: true,
    [ConsumptionChartAxis.AMPERAGE]: true,
    [ConsumptionChartAxis.PERCENTAGE]: false,
    [ConsumptionChartAxis.VOLTAGE]: false,
    [ConsumptionChartAxis.AMOUNT]: false,
  };
  let visibleDatasets = [
    ConsumptionChartDatasetOrder.INSTANT_WATTS,
    ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_WH,
    ConsumptionChartDatasetOrder.STATE_OF_CHARGE,
    ConsumptionChartDatasetOrder.LIMIT_WATTS,
  ];

  const { refetch: fetchCosumption } = useQuery(
    'Cosumptions',
    () => {
      //EVES-1908 - Show full resolution chart
      //Developer - Abhishek Bharti
      //Date - 23-10-2024
      disableLoaderForOnce()

      if (!isfullResolutionChart) {
        return getConnectorConsumption(currentTransactionID)
      } else {
        return getConnectorAllConsumption(currentTransactionID)
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        if (data?.values?.length > 0) {
          rawDatasets = data;
          currencyUnit =
            getSymbolFromCurrency(data?.priceUnit) || DEFAULT_CURRENCY_CODE;
          updateVisibleDatasets();
          onUnitChange(selectedUnit, true);
        } else {
          const chartCtx = document.getElementById(
            `chart-${currentTransactionID}`
          ) as HTMLCanvasElement;
          if (chartCtx) {
            chart = new ChartJS((chartCtx as any)?.getContext('2d'), {
              type: 'line',
              data: chartData,
            });
          }
        }
      },
    }
  );
  useImperativeHandle(ref, () => ({
    refreshChart: () => {
      fetchCosumption();
    },
  }));

  useEffect(() => {
    //EVES-1908 - Show full resolution chart
    //Developer - Abhishek Bharti
    //Date - 23-10-2024
    fetchCosumption()
    
    const intervalId = setInterval(() => {
      fetchCosumption()
    }, 10000) // 10000ms = 10 seconds

    return () => clearInterval(intervalId)
  }, [isfullResolutionChart, currentTransactionID]);

  useEffect(() => {
    selectedUnit = ConsumptionUnit.KILOWATT;
    fetchCosumption();
  }, []);

  useEffect(() => {
    if (
      location?.pathname === '/transactions/in-progress' ||
      location?.pathname === '/transactions/history' ||
      location?.pathname === '/transactions' ||
      location?.pathname === '/gridify/in-progress'
    ) {
      setDataAvailability(true);
    } else {
      setDataAvailability(false);
    }
  }, [location]);

  const updateVisibleDatasets = () => {
    if (chartData.datasets.length > 0) {
      visibleDatasets = [];
      chartData.datasets.forEach((ds) => {
        if (!ds.hidden && ds.order) {
          visibleDatasets.push(ConsumptionChartAxis[ds.order]);
        }
      });
    }
  };

  const updateVisibleGridLines = (saveState: boolean = false) => {
    const visibleDatasets = chartData.datasets
      .filter((ds) => !ds.hidden)
      .map((ds) => ds.order);
    if (!saveState) {
      for (const key in gridDisplay) {
        if (Object.prototype.hasOwnProperty.call(gridDisplay, key)) {
          gridDisplay[key] = false;
        }
      }
    }
    if (visibleDatasets) visibleLines = [...visibleDatasets];

    if (
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_WATTS) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_WATTS_L1) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_WATTS_L2) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_WATTS_L3) ||
      visibleDatasets.includes(
        ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_WH
      ) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.LIMIT_WATTS)
    ) {
      gridDisplay[ConsumptionChartAxis.POWER] = true;
    } else if (
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_AMPS) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_AMPS_L1) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_AMPS_L2) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_AMPS_L3) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_AMPS_DC) ||
      visibleDatasets.includes(
        ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_AMPS
      ) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.LIMIT_AMPS)
    ) {
      gridDisplay[ConsumptionChartAxis.AMPERAGE] = true;
    } else if (
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_VOLTS) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_VOLTS_L1) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_VOLTS_L2) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_VOLTS_L3) ||
      visibleDatasets.includes(ConsumptionChartDatasetOrder.INSTANT_VOLTS_DC)
    ) {
      gridDisplay[ConsumptionChartAxis.VOLTAGE] = true;
    } else if (
      visibleDatasets.includes(ConsumptionChartDatasetOrder.STATE_OF_CHARGE)
    ) {
      gridDisplay[ConsumptionChartAxis.PERCENTAGE] = true;
    } else if (
      visibleDatasets.includes(ConsumptionChartDatasetOrder.CUMULATED_AMOUNT)
    ) {
      gridDisplay[ConsumptionChartAxis.AMOUNT] = true;
    }
  };

  const getChartOptions = (): ChartOptions => {
    return {
      animation: { duration: 0 },
      responsive: true,
      maintainAspectRatio: true,
      spanGaps: true,
      normalized: true,
      hover: { mode: 'index', intersect: false },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          position: 'bottom' as const,
          labels: {
            color: GraphColor.chartDefaultColor,
          },
          onHover: (_e, legendItem, legend) => {
            if (legendItem.datasetIndex !== undefined) {
              const status =
                legend.chart.data.datasets[legendItem.datasetIndex].hidden;
              if (!status) {
                legend.chart.data.datasets.forEach(
                  (dataset) => (dataset.borderWidth = 1)
                );
                legend.chart.data.datasets[
                  legendItem.datasetIndex
                ].borderWidth = 5;
                legend.chart.update();
              }
            }
          },
          onLeave: (_e, _legendItem, legend) => {
            legend.chart.data.datasets.forEach(
              (dataset) => (dataset.borderWidth = 3)
            );
            legend.chart.update();
          },
          onClick: (_e, legendItem, legend) => {
            updateVisibleGridLines();
            if (legendItem.datasetIndex !== undefined) {
              const status =
                legend.chart.data.datasets[legendItem.datasetIndex].hidden;
              legend.chart.data.datasets[legendItem.datasetIndex].hidden =
                !status;
              chartData.datasets[legendItem.datasetIndex].hidden = !status;
              legend.chart.update();
            }
          },
        },
        tooltip: {
          bodySpacing: 5,
          mode: 'index',
          position: 'nearest',
          intersect: false,
          multiKeyBackground: toRgba(GraphColor.instantPowerAmpsColor, 0.7),
          callbacks: {
            labelColor: function (context) {
              return {
                borderColor: context.dataset.borderColor as Color,
                backgroundColor: context.dataset.borderColor as Color,
                dash: context.dataset.borderDash,
              };
            },
            label: (context) => {
              const dataset = context.dataset;
              const value = dataset.data[context.dataIndex] as number;
              const label = context.dataset.label;
              let tooltipLabel = '';
              switch (context.dataset.order) {
                case ConsumptionChartDatasetOrder.INSTANT_WATTS:
                  tooltipLabel = ' ' + FormateNumber(value / 1000, 2) + ' kW';
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_AMPS:
                  tooltipLabel = ' ' + FormateNumber(value, 0) + ' A';
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_WATTS_L1:
                  tooltipLabel =
                    ' ' + FormateNumber(value / 1000, 2) + ' kW L1';
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_WATTS_L2:
                  tooltipLabel =
                    ' ' + FormateNumber(value / 1000, 2) + ' kW L2';
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_WATTS_L3:
                  tooltipLabel =
                    ' ' + FormateNumber(value / 1000, 2) + ' kW L3';
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_AMPS_L1:
                  tooltipLabel = ' ' + FormateNumber(value, 0) + ' A L1';
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_AMPS_L2:
                  tooltipLabel = ' ' + FormateNumber(value, 0) + ' A L2';
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_AMPS_L3:
                  tooltipLabel = ' ' + FormateNumber(value, 0) + ' A L3';
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_AMPS_DC:
                  tooltipLabel = ' ' + FormateNumber(value, 0) + ' A DC';
                  break;
                case ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_WH:
                  tooltipLabel = ' ' + FormateNumber(value / 1000, 2) + ' kW.h';
                  break;
                case ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_AMPS:
                  tooltipLabel = ' ' + FormateNumber(value, 2) + ' A.h';
                  break;
                case ConsumptionChartDatasetOrder.LIMIT_WATTS:
                  tooltipLabel = ' ' + FormateNumber(value / 1000, 2) + ' kW';
                  break;
                case ConsumptionChartDatasetOrder.LIMIT_AMPS:
                  tooltipLabel = ' ' + FormateNumber(value, 0) + ' A';
                  break;
                case ConsumptionChartDatasetOrder.STATE_OF_CHARGE:
                  tooltipLabel = ` ${value} %`;
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_VOLTS:
                  tooltipLabel = ' ' + FormateNumber(value, 2) + ' V';
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_VOLTS_DC:
                  tooltipLabel = ' ' + FormateNumber(value, 2) + ' V DC';
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_VOLTS_L1:
                  tooltipLabel = ' ' + FormateNumber(value, 2) + ' V L1';
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_VOLTS_L2:
                  tooltipLabel = ' ' + FormateNumber(value, 2) + ' V L2';
                  break;
                case ConsumptionChartDatasetOrder.INSTANT_VOLTS_L3:
                  tooltipLabel = ' ' + FormateNumber(value, 2) + ' V L3';
                  break;
                case ConsumptionChartDatasetOrder.AMOUNT:
                  tooltipLabel = currencyUnit + FormateNumber(value, 2);
                  break;
                case ConsumptionChartDatasetOrder.CUMULATED_AMOUNT:
                  tooltipLabel = currencyUnit + FormateNumber(value, 2);
                  break;
                default:
                  tooltipLabel = value + '';
              }
              return `${label}: ${tooltipLabel}`;
            },
            title: (tooltipItems) => {
              const firstDate = new Date(firstLabel);
              const currentDate = new Date(tooltipItems[0].parsed.x);
              return (
                Formatter.toDateString(firstDate) +
                ', ' +
                currentDate.toLocaleTimeString(navigator.language, {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                }) +
                ' - ' +
                Formatter.duration(firstDate, currentDate)
              );
            },
          },
        },
      },
      scales: {
        [ConsumptionChartAxis.X]: {
          type: 'time',
          time: {
            tooltipFormat: 'h:mm a',
            unit: 'minute',
            displayFormats: { second: 'h:mm:ss a', minute: 'h:mm a' },
          },
          grid: { display: true, color: 'rgba(0,0,0,0.2)' },
          ticks: {
            autoSkip: true,
            color: GraphColor.chartDefaultColor,
          },
        },
        [ConsumptionChartAxis.POWER]: {
          type: 'linear',
          position: 'left',
          display: 'auto',
          grid: {
            display: true,
            drawOnChartArea: gridDisplay[ConsumptionChartAxis.POWER],
            color: 'rgba(0,0,0,0.2)',
          },
          ticks: {
            color: GraphColor.chartDefaultColor,
            callback: function (value) {
              return FormateNumber(Number(value) / 1000, 0, 2) + selectedUnit;
            },
          },
        },
        [ConsumptionChartAxis.AMPERAGE]: {
          type: 'linear',
          position: 'left',
          display: 'auto',
          grid: {
            display: true,
            drawOnChartArea: gridDisplay[ConsumptionChartAxis.AMPERAGE],
            color: 'rgba(0,0,0,0.2)',
          },
          ticks: {
            color: GraphColor.chartDefaultColor,
            callback: function (value) {
              return FormateNumber(Number(value), 0, 2) + 'A';
            },
          },
        },
        [ConsumptionChartAxis.VOLTAGE]: {
          type: 'linear',
          position: 'left',
          display: 'auto',
          grid: {
            display: true,
            drawOnChartArea: gridDisplay[ConsumptionChartAxis.VOLTAGE],
            color: 'rgba(0,0,0,0.2)',
          },
          ticks: {
            color: GraphColor.chartDefaultColor,
            callback: function (value) {
              return FormateNumber(Number(value), 0, 2) + 'V';
            },
          },
        },
        [ConsumptionChartAxis.AMOUNT]: {
          type: 'linear',
          position: 'right',
          display: 'auto',
          min: 0,
          grid: {
            display: true,
            drawOnChartArea: gridDisplay[ConsumptionChartAxis.AMOUNT],
            color: 'rgba(0,0,0,0.2)',
          },
          ticks: {
            color: GraphColor.chartDefaultColor,
            callback: function (value) {
              return currencyUnit + FormateNumber(Number(value), 0, 2);
            },
          },
        },
        [ConsumptionChartAxis.PERCENTAGE]: {
          type: 'linear',
          position: 'right',
          display: 'auto',
          grid: {
            display: true,
            drawOnChartArea: gridDisplay[ConsumptionChartAxis.PERCENTAGE],
            color: 'rgba(0,0,0,0.2)',
          },
          ticks: {
            color: GraphColor.chartDefaultColor,
            callback: (value) => `${value}%`,
          },
        },
      },
    } as ChartOptions;
  };

  const createGraphData = (force: boolean = false) => {
    const datasets: ChartDataset[] = [];
    // Instant Amps/Power
    datasets.push({
      type: 'line',
      data: [],
      tension: 0,
      ...FormatLineColor(GraphColor.instantPowerAmpsColor),
      hidden:
        force && visibleLines.length
          ? !(
              visibleLines.includes(
                ConsumptionChartDatasetOrder.INSTANT_AMPS
              ) ||
              visibleLines.includes(ConsumptionChartDatasetOrder.INSTANT_WATTS)
            )
          : visibleDatasets.indexOf(
              ConsumptionChartDatasetOrder.INSTANT_AMPS
            ) === -1 &&
            visibleDatasets.indexOf(
              ConsumptionChartDatasetOrder.INSTANT_WATTS
            ) === -1,
      fill: 'origin',
      yAxisID:
        selectedUnit === ConsumptionUnit.AMPERE
          ? ConsumptionChartAxis.AMPERAGE
          : ConsumptionChartAxis.POWER,
      label:
        selectedUnit === ConsumptionUnit.AMPERE
          ? `${translate('transactions.graph.amps')}`
          : `${translate('transactions.graph.power')}`,
      order:
        selectedUnit === ConsumptionUnit.AMPERE
          ? ConsumptionChartDatasetOrder.INSTANT_AMPS
          : ConsumptionChartDatasetOrder.INSTANT_WATTS,
    });
    // Power/Amps L1/L2/L3
    if (
      rawDatasets.values.find(
        (consumption) => consumption.instantWattsL1 > 0
      ) ||
      rawDatasets.values.find(
        (consumption) => consumption.instantWattsL2 > 0
      ) ||
      rawDatasets.values.find((consumption) => consumption.instantWattsL3 > 0)
    ) {
      datasets.push(
        {
          type: 'line',
          data: [],
          tension: 0,
          ...FormatLineColor(GraphColor.instantPowerAmpsL1Color),
          hidden:
            force && visibleLines.length
              ? !(
                  visibleLines.includes(
                    ConsumptionChartDatasetOrder.INSTANT_AMPS_L1
                  ) ||
                  visibleLines.includes(
                    ConsumptionChartDatasetOrder.INSTANT_WATTS_L1
                  )
                )
              : visibleDatasets.indexOf(
                  ConsumptionChartDatasetOrder.INSTANT_AMPS_L1
                ) === -1 &&
                visibleDatasets.indexOf(
                  ConsumptionChartDatasetOrder.INSTANT_WATTS_L1
                ) === -1,
          fill: 'origin',
          yAxisID:
            selectedUnit === ConsumptionUnit.AMPERE
              ? ConsumptionChartAxis.AMPERAGE
              : ConsumptionChartAxis.POWER,
          label:
            selectedUnit === ConsumptionUnit.AMPERE
              ? `${translate('transactions.graph.amps_l1')}`
              : `${translate('transactions.graph.power_l1')}`,
          order:
            selectedUnit === ConsumptionUnit.AMPERE
              ? ConsumptionChartDatasetOrder.INSTANT_AMPS_L1
              : ConsumptionChartDatasetOrder.INSTANT_WATTS_L1,
        },
        {
          type: 'line',
          data: [],
          tension: 0,
          ...FormatLineColor(GraphColor.instantPowerAmpsL2Color),
          hidden:
            force && visibleLines.length
              ? !(
                  visibleLines.includes(
                    ConsumptionChartDatasetOrder.INSTANT_AMPS_L2
                  ) ||
                  visibleLines.includes(
                    ConsumptionChartDatasetOrder.INSTANT_WATTS_L2
                  )
                )
              : visibleDatasets.indexOf(
                  ConsumptionChartDatasetOrder.INSTANT_AMPS_L2
                ) === -1 &&
                visibleDatasets.indexOf(
                  ConsumptionChartDatasetOrder.INSTANT_WATTS_L2
                ) === -1,
          fill: 'origin',
          yAxisID:
            selectedUnit === ConsumptionUnit.AMPERE
              ? ConsumptionChartAxis.AMPERAGE
              : ConsumptionChartAxis.POWER,
          label:
            selectedUnit === ConsumptionUnit.AMPERE
              ? `${translate('transactions.graph.amps_l2')}`
              : `${translate('transactions.graph.power_l2')}`,
          order:
            selectedUnit === ConsumptionUnit.AMPERE
              ? ConsumptionChartDatasetOrder.INSTANT_AMPS_L2
              : ConsumptionChartDatasetOrder.INSTANT_WATTS_L2,
        },
        {
          type: 'line',
          data: [],
          tension: 0,
          ...FormatLineColor(GraphColor.instantPowerAmpsL3Color),
          hidden:
            force && visibleLines.length
              ? !(
                  visibleLines.includes(
                    ConsumptionChartDatasetOrder.INSTANT_AMPS_L3
                  ) ||
                  visibleLines.includes(
                    ConsumptionChartDatasetOrder.INSTANT_WATTS_L3
                  )
                )
              : visibleDatasets.indexOf(
                  ConsumptionChartDatasetOrder.INSTANT_AMPS_L3
                ) === -1 &&
                visibleDatasets.indexOf(
                  ConsumptionChartDatasetOrder.INSTANT_WATTS_L3
                ) === -1,
          fill: 'origin',
          yAxisID:
            selectedUnit === ConsumptionUnit.AMPERE
              ? ConsumptionChartAxis.AMPERAGE
              : ConsumptionChartAxis.POWER,
          label:
            selectedUnit === ConsumptionUnit.AMPERE
              ? `${translate('transactions.graph.amps_l3')}`
              : `${translate('transactions.graph.power_l3')}`,
          order:
            selectedUnit === ConsumptionUnit.AMPERE
              ? ConsumptionChartDatasetOrder.INSTANT_AMPS_L3
              : ConsumptionChartDatasetOrder.INSTANT_WATTS_L3,
        }
      );
    }
    // Limit Amps/Power
    datasets.push({
      type: 'line',
      data: [],
      tension: 0,
      ...FormatLineColor(GraphColor.limitColor),
      hidden:
        force && visibleLines.length
          ? !(
              visibleLines.includes(ConsumptionChartDatasetOrder.LIMIT_AMPS) ||
              visibleLines.includes(ConsumptionChartDatasetOrder.LIMIT_WATTS)
            )
          : visibleDatasets.indexOf(ConsumptionChartDatasetOrder.LIMIT_AMPS) ===
              -1 &&
            visibleDatasets.indexOf(
              ConsumptionChartDatasetOrder.LIMIT_WATTS
            ) === -1,
      fill: 'origin',
      yAxisID:
        selectedUnit === ConsumptionUnit.AMPERE
          ? ConsumptionChartAxis.AMPERAGE
          : ConsumptionChartAxis.POWER,
      label:
        selectedUnit === ConsumptionUnit.AMPERE
          ? `${translate('transactions.graph.limit_amps')}`
          : `${translate('transactions.graph.limit_watts')}`,
      order:
        selectedUnit === ConsumptionUnit.AMPERE
          ? ConsumptionChartDatasetOrder.LIMIT_AMPS
          : ConsumptionChartDatasetOrder.LIMIT_WATTS,
    });
    // Cumulated Amps/Power
    datasets.push({
      type: 'line',
      data: [],
      tension: 0,
      ...FormatLineColor(GraphColor.consumptionColor),
      hidden:
        force && visibleLines.length
          ? !(
              visibleLines.includes(
                ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_AMPS
              ) ||
              visibleLines.includes(
                ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_WH
              )
            )
          : visibleDatasets.indexOf(
              ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_AMPS
            ) === -1 &&
            visibleDatasets.indexOf(
              ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_WH
            ) === -1,
      fill: 'origin',
      yAxisID:
        selectedUnit === ConsumptionUnit.AMPERE
          ? ConsumptionChartAxis.AMPERAGE
          : ConsumptionChartAxis.POWER,
      label:
        selectedUnit === ConsumptionUnit.AMPERE
          ? `${translate('transactions.graph.energy_amps')}`
          : `${translate('transactions.graph.energy')}`,
      order:
        selectedUnit === ConsumptionUnit.AMPERE
          ? ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_AMPS
          : ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_WH,
    });
    // Amount
    if (
      rawDatasets.values.find((consumption) => consumption.cumulatedAmount > 0)
    ) {
      datasets.push({
        type: 'line',
        data: [],
        tension: 0,
        ...FormatLineColor(GraphColor.amountColor),
        hidden:
          force && visibleLines.length
            ? !visibleLines.includes(
                ConsumptionChartDatasetOrder.CUMULATED_AMOUNT
              )
            : visibleDatasets.indexOf(
                ConsumptionChartDatasetOrder.CUMULATED_AMOUNT
              ) === -1,
        fill: 'origin',
        yAxisID: ConsumptionChartAxis.AMOUNT,
        label: `${translate('transactions.graph.cumulated_amount')}`,
        order: ConsumptionChartDatasetOrder.CUMULATED_AMOUNT,
      });
    }
    // DC Amps
    if (
      rawDatasets.values.find((consumption) => consumption.instantAmpsDC > 0)
    ) {
      datasets.push({
        type: 'line',
        data: [],
        tension: 0,
        ...FormatLineColor(GraphColor.instantAmpsDCColor),
        hidden:
          force && visibleLines.length
            ? !visibleLines.includes(
                ConsumptionChartDatasetOrder.INSTANT_AMPS_DC
              )
            : visibleDatasets.indexOf(
                ConsumptionChartDatasetOrder.INSTANT_AMPS_DC
              ) === -1,
        fill: 'origin',
        yAxisID: ConsumptionChartAxis.AMPERAGE,
        label: `${translate('transactions.graph.amperage_dc')}`,
        order: ConsumptionChartDatasetOrder.INSTANT_AMPS_DC,
      });
    }
    // Voltage
    if (
      rawDatasets.values.find((consumption) => consumption.instantVolts > 0)
    ) {
      datasets.push({
        type: 'line',
        data: [],
        tension: 0,
        ...FormatLineColor(GraphColor.instantVoltsColor),
        hidden:
          force && visibleLines.length
            ? !visibleLines.includes(ConsumptionChartDatasetOrder.INSTANT_VOLTS)
            : visibleDatasets.indexOf(
                ConsumptionChartDatasetOrder.INSTANT_VOLTS
              ) === -1,
        fill: 'origin',
        yAxisID: ConsumptionChartAxis.VOLTAGE,
        label: `${translate('transactions.graph.voltage')}`,
        order: ConsumptionChartDatasetOrder.INSTANT_VOLTS,
      });
    }
    // DC Voltage
    if (
      rawDatasets.values.find((consumption) => consumption.instantVoltsDC > 0)
    ) {
      datasets.push({
        type: 'line',
        data: [],
        tension: 0,
        ...FormatLineColor(GraphColor.instantVoltsColor),
        hidden:
          force && visibleLines.length
            ? !visibleLines.includes(
                ConsumptionChartDatasetOrder.INSTANT_VOLTS_DC
              )
            : visibleDatasets.indexOf(
                ConsumptionChartDatasetOrder.INSTANT_VOLTS_DC
              ) === -1,
        fill: 'origin',
        yAxisID: ConsumptionChartAxis.VOLTAGE,
        label: `${translate('transactions.graph.voltage_dc')}`,
        order: ConsumptionChartDatasetOrder.INSTANT_VOLTS_DC,
      });
    }
    // Voltage L1/L2/L3
    if (
      rawDatasets.values.find(
        (consumption) => consumption.instantVoltsL1 > 0
      ) ||
      rawDatasets.values.find(
        (consumption) => consumption.instantVoltsL2 > 0
      ) ||
      rawDatasets.values.find((consumption) => consumption.instantVoltsL3 > 0)
    ) {
      datasets.push(
        {
          type: 'line',
          data: [],
          tension: 0,
          ...FormatLineColor(GraphColor.instantVoltsL1Color),
          hidden:
            force && visibleLines.length
              ? !visibleLines.includes(
                  ConsumptionChartDatasetOrder.INSTANT_VOLTS_L1
                )
              : visibleDatasets.indexOf(
                  ConsumptionChartDatasetOrder.INSTANT_VOLTS_L1
                ) === -1,
          fill: 'origin',
          yAxisID: ConsumptionChartAxis.VOLTAGE,
          label: `${translate('transactions.graph.voltage_l1')}`,
          borderDash: [1, 1],
          order: ConsumptionChartDatasetOrder.INSTANT_VOLTS_L1,
        },
        {
          type: 'line',
          data: [],
          tension: 0,
          ...FormatLineColor(GraphColor.instantVoltsL2Color),
          hidden:
            force && visibleLines.length
              ? !visibleLines.includes(
                  ConsumptionChartDatasetOrder.INSTANT_VOLTS_L2
                )
              : visibleDatasets.indexOf(
                  ConsumptionChartDatasetOrder.INSTANT_VOLTS_L2
                ) === -1,
          fill: 'origin',
          yAxisID: ConsumptionChartAxis.VOLTAGE,
          label: `${translate('transactions.graph.voltage_l2')}`,
          borderDash: [3, 3],
          order: ConsumptionChartDatasetOrder.INSTANT_VOLTS_L2,
        },
        {
          type: 'line',
          data: [],
          tension: 0,
          ...FormatLineColor(GraphColor.instantVoltsL3Color),
          hidden:
            force && visibleLines.length
              ? !visibleLines.includes(
                  ConsumptionChartDatasetOrder.INSTANT_VOLTS_L3
                )
              : visibleDatasets.indexOf(
                  ConsumptionChartDatasetOrder.INSTANT_VOLTS_L3
                ) === -1,
          fill: 'origin',
          yAxisID: ConsumptionChartAxis.VOLTAGE,
          label: `${translate('transactions.graph.voltage_l3')}`,
          borderDash: [5, 5],
          order: ConsumptionChartDatasetOrder.INSTANT_VOLTS_L3,
        }
      );
    }
    // SoC
    if (
      rawDatasets.values.find(
        (consumption) =>
          consumption.stateOfCharge > 0 ||
          (consumption.stop && consumption.stop.stateOfCharge > 0)
      )
    ) {
      datasets.push({
        type: 'line',
        data: [],
        tension: 0,
        ...FormatLineColor(GraphColor.stateOfChargeColor),
        hidden:
          force && visibleLines.length
            ? !visibleLines.includes(
                ConsumptionChartDatasetOrder.STATE_OF_CHARGE
              )
            : visibleDatasets.indexOf(
                ConsumptionChartDatasetOrder.STATE_OF_CHARGE
              ) === -1,
        fill: 'origin',
        yAxisID: ConsumptionChartAxis.PERCENTAGE,
        label: `${translate('transactions.graph.battery')}`,
        order: ConsumptionChartDatasetOrder.STATE_OF_CHARGE,
      });
    }

    return datasets;
  };

  const processRawDataset = (
    rawDatasets: any,
    chartPartialDataset: ChartDataset[]
  ): [ChartDataset[], any[], any[]] => {
    const labels: any[] = [];
    const endDates: any[] = [];
    rawDatasets.values.forEach((value: any) => {
      const dateTime = new Date(value.startedAt);
      const endDateTime = new Date(value.endedAt);
      labels.push(dateTime.getTime());
      endDates.push(endDateTime.getTime());
      chartPartialDataset.forEach((d) => {
        switch (d.order) {
          case ConsumptionChartDatasetOrder.INSTANT_WATTS:
            d.data.push(value.instantWatts);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_WATTS_L1:
            d.data.push(value.instantWattsL1);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_WATTS_L2:
            d.data.push(value.instantWattsL2);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_WATTS_L3:
            d.data.push(value.instantWattsL3);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_AMPS:
            d.data.push(value.instantAmps);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_AMPS_L1:
            d.data.push(value.instantAmpsL1);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_AMPS_L2:
            d.data.push(value.instantAmpsL2);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_AMPS_L3:
            d.data.push(value.instantAmpsL3);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_AMPS_DC:
            d.data.push(value.instantAmpsDC);
            break;
          case ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_WH:
            d.data.push(value.cumulatedConsumptionWh);
            break;
          case ConsumptionChartDatasetOrder.CUMULATED_CONSUMPTION_AMPS:
            d.data.push(value.cumulatedConsumptionAmps);
            break;
          case ConsumptionChartDatasetOrder.LIMIT_WATTS:
            d.data.push(value.limitWatts);
            break;
          case ConsumptionChartDatasetOrder.LIMIT_AMPS:
            d.data.push(value.limitAmps);
            break;
          case ConsumptionChartDatasetOrder.STATE_OF_CHARGE:
            d.data.push(value.stateOfCharge);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_VOLTS:
            d.data.push(value.instantVolts);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_VOLTS_DC:
            d.data.push(value.instantVoltsDC);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_VOLTS_L1:
            d.data.push(value.instantVoltsL1);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_VOLTS_L2:
            d.data.push(value.instantVoltsL2);
            break;
          case ConsumptionChartDatasetOrder.INSTANT_VOLTS_L3:
            d.data.push(value.instantVoltsL3);
            break;
          case ConsumptionChartDatasetOrder.AMOUNT:
            d.data.push(value.cumulatedAmount);
            break;
          case ConsumptionChartDatasetOrder.CUMULATED_AMOUNT:
            d.data.push(value.cumulatedAmount);
            break;
        }
      });
    });

    return [chartPartialDataset, labels, endDates];
  };

  const onUnitChange = (
    unit: ConsumptionUnit,
    initialisation: boolean = false
  ) => {
    selectedUnit = unit;
    const chartCtx = document.getElementById(
      `chart-${currentTransactionID}`
    ) as HTMLCanvasElement;
    if (chartCtx) {
      if (initialisation) {
        const chartPartialDataset = createGraphData();
        if (chartPartialDataset) {
          for (let index = 0; index < chartPartialDataset.length; index++) {
            chartPartialDataset[index].data = [];
          }
          const [datasets, labels, endDates] = processRawDataset(
            rawDatasets,
            chartPartialDataset
          );
          const updatedChartData = {
            datasets: [...datasets],
            labels: [...labels],
          };

          updateVisibleDatasets();

          firstLabel = labels[0];
          // For displaying load All consumptions
          if (chart) {
            chart.data.labels = [];
            chart.data.datasets.forEach((dataset: any, index: number) => {
              dataset.data = updatedChartData?.datasets[index]?.data;
            });

            updatedChartData?.labels.forEach((label: any) => {
              chart.data.labels.push(label);
            });

            chart.update();
          }

          if (!(chartCtx as any)?.['$chartjs'] || !chart) {
            chart = new ChartJS((chartCtx as any).getContext('2d'), {
              type: 'line',
              data: updatedChartData,
              options: getChartOptions(),
            });
          }

          setchartData({ ...updatedChartData });
          setEndDate(endDates);
        }
      } else {
        updateVisibleGridLines(true);
        const chartPartialDataset = createGraphData(true);
        const [datasets, labels, endDates] = processRawDataset(
          rawDatasets,
          chartPartialDataset
        );
        const updatedChartData = {
          datasets: [...datasets],
          labels: [...labels],
        };
        chart = ChartJS.getChart(chartCtx);
        setchartData({ ...updatedChartData });
        setEndDate(endDates);
        chart.data.datasets = [...updatedChartData.datasets];
        chart.options = getChartOptions();
        chart.update();
        chart.draw();
      }
    }
  };

  const exportChargerHandler = (value: string) => {
    if (value === 'Export Excel') {
      exportChartInExcel(chartData, endDate);
    } else {
      exportChartInCSV(chartData, endDate);
    }

    setExportCharger(false);
  };
  return (
    <div
      style={{ ...chartStyles.container, left: `${props?.leftPosition}px` }}
      className={styles.containerStyles}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        marginBottom={'5px'}
        padding={'10px 0'}
      >
        {dataAvailable && (
          <>
            {' '}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                position: 'relative',
              }}
            >
              <EvesButton
                onClick={() => setExportCharger(!exportCharger)}
                data-cy={'exportButton'}
                sx={{
                  width: '120px',
                  borderBottom: '1px solid #FFFFFF',
                }}
                endIcon={<ExpandMore />}
                id='exportButton'
              >
                Export
              </EvesButton>
              {exportCharger && (
                <Box
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '120px',
                    zIndex: 1,
                    top: '35px',
                  }}
                  data-cy='exportContainer'
                >
                  <ButtonGroup
                    orientation='vertical'
                    sx={{
                      fontSize: '12px !important',
                      width: '124px',
                      '& .MuiButtonGroup-grouped:not(:first-of-type)': {
                        borderTop: '1px solid #FFFFFF',
                        '&:hover': {
                          background: '#2196f3',
                        },
                      },
                      '& .MuiButtonGroup-grouped:not(:last-of-type)': {
                        borderTop: '1px solid #FFFFFF',
                        '&:hover': {
                          background: '#2196f3',
                        },
                      },
                    }}
                    data-cy={'exportDropdown'}
                  >
                    <EvesButton
                      onClick={() => exportChargerHandler('Export CSV')}
                      data-cy='csv'
                    >
                      Export CSV
                    </EvesButton>
                    <EvesButton
                      onClick={() => exportChargerHandler('Export Excel')}
                      data-cy='excel'
                    >
                      Export Excel
                    </EvesButton>
                  </ButtonGroup>
                </Box>
              )}
            </Box>
          </>
        )}
        <FormControlLabel
          control={
            <EvesCheckbox
              checked={isfullResolutionChart}
              onChange={() => setIsfullResolutionChart(!isfullResolutionChart)}
              data-cy='chart-full-resolution-checkbox'
            />
          }
          label={`${translate('transactions.graph.load_all_consumptions')}`}
        />
        <Box sx={{ width: '200px' }}>
          <ChargingUnitSelectorComponent
            selectedUnit={selectedUnit}
            onUnitChange={onUnitChange}
          />
        </Box>
      </Stack>
      <canvas
        id={`chart-${currentTransactionID}`}
        key={`chart-key-${currentTransactionID}`}
        style={chartStyles.canvas}
        data-cy='consumption-graph'
      ></canvas>
    </div>
  );
});

export default ConsumptionChart;

import { Grid } from '@mui/material';
import {  Line } from 'react-chartjs-2';

import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

const MonthlyGraph = ({ groupBy, demandLimit, apiData, demandLimitValue }: any) => {
  
  const [data, setDataC] = useState([
    {
      type: 'bar',
      label: 'Data',
      data: [],
      borderColor: '#2D9CDB',
      borderWidth: 0,
      fill: true,
      backgroundColor: '#2D9CDB',
      borderRadius: 5,
      barPercentage: groupBy == '_30days' ? 1 : 0.35,
    }
  ]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Demand (KW)',
          color: 'black',
          padding: 5,
        },
        grid: {
          display: false,
        },
        ticks: {
          // stepSize: 500,
        },
      },
    },
  };

  const labeldata = apiData ? apiData?.map((item: any) => {
    const date = new Date(item.dateInterval);
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' });
  }) : [];

  const chartData: any = {
    labels: labeldata,
    datasets: data,
  };

  useEffect(() => {
    if (!apiData) return;

    const dataF: any = [{
      type: 'bar',
      label: 'Data',
      data: apiData?.map((item: any) => item.instantValue),
      borderColor: '#2D9CDB',
      borderWidth: 0,
      fill: true,
      backgroundColor: '#2D9CDB',
      borderRadius: 5,
      barPercentage: groupBy == '_30days' ? 1 : 0.35,
    }];

    if (demandLimit) {
      dataF.push({
        type: 'line',
        label: 'Line Dataset',
        borderColor: '#F79009',
        borderWidth: 2,
        backgroundColor: '#F79009',
        borderRadius: 0,
        data: Array(apiData.length).fill(demandLimitValue || 0),
      });
    }

    setDataC(dataF);
  }, [demandLimit, apiData, groupBy, demandLimitValue]);

 

  return (
    <Grid container sx={{ display: 'flex' }}>
      {apiData && apiData.length > 0 ? (
        <Grid
          className={styles.graphContainer}
          data-cy='energy-usage-bargraph'
        >
          <Line data={chartData} options={options} />
        </Grid>
      ) : (
        <Grid
          className={styles.graphContainer}
          data-cy='energy-usage-bargraph'
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          No monthly data available
        </Grid>
      )}
    </Grid>
  );
};

export default MonthlyGraph;

import { Grid, InputAdornment, IconButton } from '@mui/material';
import GeoMap from '@/common/components/organisms/geoMap/geoMap';
import { useState } from 'react';
import { PinDrop } from '@mui/icons-material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { AddressFormModel } from '@/common/models/model';
import { EvesButton } from './atoms/button/button';
import { EvesTextbox } from './atoms/textbox/textbox';
import { LoadScriptNext, StandaloneSearchBox } from '@react-google-maps/api';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
import { IUserContextModel } from '@/store/auth/types';
import { useSelector } from 'react-redux';

interface IAddressComponentProps {
  name?: string;
  label?: string;
  showViewPlace?: boolean;
  formName?: string;
  onFieldChange?: (key: string, value: string) => void;
  onViewPlaceFormChange?: (value: boolean) => void;
  handleFormInvalid?: (value: any) => void;
  isDisabled?: boolean;
  requiredFields: any;
  hideGeoLocation?: any;
  hostForm: UseFormReturn<
    { [key: string]: any; address: AddressFormModel },
    any
  >;
}

export default function AddressComponent(props: IAddressComponentProps) {
  const { t: translate } = useTranslation();
  const authContext: IUserContextModel = useSelector(
    (state: any) => state.userContext
  );
  const [showMap, setShowMap] = useState(false);
  const placeSearchBoxRef: google.maps.places.SearchBox | any = null;
  const [place, setPlace] = useState(placeSearchBoxRef);
  const { control, formState, watch } = props.hostForm;
  const errors = formState?.errors?.address;
  const longitude = watch('address.coordinates[0]');
  const latitude = watch('address.coordinates[1]');
  const coordinateError = formState?.errors?.address?.coordinates;
  const libs: any = ['places'];
  const MAPS_API_KEY: string = process.env.REACT_APP_MAPS_API_KEY ?? '';
  const onPlaceSelect = () => {
    if (place !== null) {
      const places = place?.getPlaces();
      if (places && places.length > 0) {
        const selectedPlace = places[0];
        // Set data
        let streetNumber = '';
        let route = '';
        selectedPlace.address_components.forEach((addressComponent: any) => {
          switch (addressComponent.types[0]) {
            // Postal Code
            case 'postal_code':
              props.hostForm.setValue(
                'address.postalCode',
                addressComponent.long_name
              );
              break;
            // Town
            case 'locality':
              props.hostForm.setValue(
                'address.city',
                addressComponent.long_name
              );
              break;
            // Department
            case 'administrative_area_level_2':
              props.hostForm.setValue(
                'address.department',
                addressComponent.long_name
              );
              break;
            // Region
            case 'administrative_area_level_1':
              props.hostForm.setValue(
                'address.region',
                addressComponent.long_name
              );
              break;
            // Country
            case 'country':
              props.hostForm.setValue(
                'address.country',
                addressComponent.long_name
              );
              break;
            case 'route':
              route = addressComponent.long_name;
              break;
            case 'street_number':
              streetNumber = addressComponent.long_name;
              break;
          }
        });
        // build Address 2
        const address2 = `${streetNumber} ${route}`;
        // Address
        props.hostForm.setValue('address.address1', selectedPlace.name);
        // Set Address 2 if different from Address 1
        if (address2 !== selectedPlace.name) {
          props.hostForm.setValue('address.address2', address2);
        } else {
          props.hostForm.setValue('address.address2', '');
        }
        // Latitude
        props.hostForm.setValue(
          'address.coordinates[1]' as string,
          selectedPlace.geometry.location.lat(),
          { shouldValidate: true, shouldDirty: true }
        );
        props.hostForm.setValue(
          'address.coordinates[0]' as string,
          selectedPlace.geometry.location.lng(),
          { shouldValidate: true, shouldDirty: true }
        );
      }
    }
  };

  const onPlaceSearchBoxLoad = (searchBox: google.maps.places.SearchBox) => {
    setPlace(searchBox);
  };

  const onLocationPick = (latlng: any) => {
    setShowMap(false);
    props.hostForm.setValue('address.coordinates[0]' as string, latlng.lng, {
      shouldValidate: true,
      shouldDirty: true,
    });
    props.hostForm.setValue('address.coordinates[1]' as string, latlng.lat, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleClose = () => {
    setShowMap(false);
  };
  const onViewLocation = () => {
    const coordinates = props.hostForm.getValues('address.coordinates');
    window.open(
      'https://maps.google.com?q=' + coordinates?.[1] + ',' + coordinates?.[0]
    );
  };

  return (
    <>
      <Grid item xs={6}>
        <LoadScriptNext googleMapsApiKey={MAPS_API_KEY} libraries={libs}>
          <StandaloneSearchBox
            onLoad={onPlaceSearchBoxLoad}
            onPlacesChanged={onPlaceSelect}
          >
            <Controller
              name='address.address1'
              control={control}
              render={({ field }) => (
                <EvesTextbox
                  {...field}
                  id='address1'
                  label={`${translate('general.address1')}`}
                  fullWidth
                  placeholder=''
                  disabled={props.isDisabled}
                  required={props.requiredFields['address.address1']}
                  variant='standard'
                  error={!!errors?.address1}
                  helperText={`${translate(errors?.address1?.message || ' ')}`}
                />
              )}
            />
          </StandaloneSearchBox>
        </LoadScriptNext>
      </Grid>

      <Grid item xs={6}>
        <Controller
          name='address.address2'
          control={control}
          render={({ field }) => (
            <EvesTextbox
              {...field}
              id='address2'
              label={`${translate('general.address2')}`}
              fullWidth
              disabled={props.isDisabled}
              required={props.requiredFields['address.address2']}
              variant='standard'
              error={!!errors?.address2}
              helperText={`${translate(errors?.address2?.message || ' ')}`}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name='address.postalCode'
          control={control}
          render={({ field }) => (
            <EvesTextbox
              {...field}
              id='postalCode'
              label={`${translate('general.postal_code')}`}
              fullWidth
              disabled={props.isDisabled}
              required={props.requiredFields['address.postalCode']}
              variant='standard'
              error={!!errors?.postalCode}
              helperText={`${translate(errors?.postalCode?.message || ' ')}`}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name='address.city'
          control={control}
          render={({ field }) => (
            <EvesTextbox
              {...field}
              id='city'
              label={`${translate('general.city')}`}
              fullWidth
              disabled={props.isDisabled}
              required={props.requiredFields['address.city']}
              variant='standard'
              error={!!errors?.city}
              helperText={`${translate(errors?.city?.message || ' ')}`}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          name='address.department'
          control={control}
          render={({ field }) => (
            <EvesTextbox
              {...field}
              id='department'
              label={`${translate('general.department')}`}
              fullWidth
              disabled={props.isDisabled}
              required={props.requiredFields['address.department']}
              variant='standard'
              error={!!errors?.department}
              helperText={`${translate(errors?.department?.message || ' ')}`}
            />
          )}
        />
      </Grid>

      <Grid item xs={3}>
        <Controller
          name='address.region'
          control={control}
          render={({ field }) => (
            <EvesTextbox
              {...field}
              id='region'
              label={`${translate('general.region')}`}
              fullWidth
              disabled={props.isDisabled}
              required={props.requiredFields['address.region']}
              variant='standard'
              error={!!errors?.region}
              helperText={`${translate(errors?.region?.message || ' ')}`}
            />
          )}
        />
      </Grid>

      <Grid item xs={3}>
        <Controller
          name='address.country'
          control={control}
          render={({ field }) => (
            <EvesTextbox
              {...field}
              id='country'
              label={`${translate('general.country')}`}
              fullWidth
              disabled={props.isDisabled}
              required={props.requiredFields['address.country']}
              variant='standard'
              error={!!errors?.country}
              helperText={`${translate(errors?.country?.message || ' ')}`}
            />
          )}
        />
      </Grid>
      {!props.hideGeoLocation && (
        <Grid item xs={3}>
          <Controller
            name='address.coordinates[1]'
            control={control}
            render={({ field }) => (
              <EvesTextbox
                {...field}
                id='latitude'
                label={`${translate('general.latitude')}`}
                fullWidth
                disabled={props.isDisabled}
                required={props.requiredFields['address.coordinates[1]']}
                variant='standard'
                error={!!errors?.coordinates?.[1]}
                helperText={`${translate(
                  errors?.coordinates?.[1]?.message || ' '
                )}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      onClick={() => setShowMap(true)}
                    >
                      <IconButton disabled={props.isDisabled}>
                        <PinDrop fontSize='small' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      )}
      {!props.hideGeoLocation && (
        <Grid item xs={3}>
          <Controller
            name='address.coordinates[0]'
            control={control}
            render={({ field }) => (
              <EvesTextbox
                {...field}
                id='longitude'
                label={`${translate('general.longitude')}`}
                fullWidth
                disabled={props.isDisabled}
                required={props.requiredFields['address.coordinates[0]']}
                variant='standard'
                error={!!errors?.coordinates?.[0]}
                helperText={`${translate(
                  errors?.coordinates?.[0]?.message || ' '
                )}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      onClick={() => setShowMap(true)}
                    >
                      <IconButton disabled={props.isDisabled}>
                        <PinDrop fontSize='small' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      )}
      
      {(authContext?.userInfo?.role === 'S' && 
        <Grid item xs={3}>
          <Controller
            name='contactNumber'
            control={control}
            render={({ field }) => (
              <EvesTextbox
                {...field}
                id='contactNumber'
                label={`${translate('general.contact')}`}
                fullWidth
                disabled={props.isDisabled}
                required={props.requiredFields['contactNumber']}
                variant='standard'
                error={!!formState.errors?.contactNumber}
                helperText={`${translate(
                  (formState?.errors?.contactNumber?.message as string) || ' '
                )}`}
              />
            )}
          />
      </Grid>
      )}
       {!props.hideGeoLocation && (
        <Grid item  mt={1}>
          <EvesButton
            data-cy='organization-create-company-view-Place'
            type='button'
            disabled={!(longitude && latitude) || coordinateError}
            onClick={onViewLocation}
            startIcon={<LocationOnIcon />}
            variant='contained'
          >
            {`${translate('general.show_place')}`}
          </EvesButton>
        </Grid>
      )}
      {showMap && (
        <GeoMap
          label={props.label}
          name={props.name}
          isOpen={showMap}
          handleClose={handleClose}
          onLocationPick={onLocationPick}
        ></GeoMap>
      )}
    </>
  );
}

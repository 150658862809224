// ErrorLogging.ts
import { ShowConfirmType } from "@/common/components/molecules/dialog/dialog";
import { DoNotifyType } from "@/common/utils/notificationService";
import axios from "axios";
export interface IContextPropsModel {
  dialogService?: {
    showConfirm: ShowConfirmType;
  };
  notificationService?: {
    notify: DoNotifyType;
  };
}
const ErrorLogging = async (data: any, toast: IContextPropsModel) => {
  const notify = {
    message: '',
    type: 'error' as const,
  };

  const payload = {
    "business": "EVES",
    "domain": "eves",
    "enviornment": "dev",
    "messageType": data?.messageType,
    "message": data?.message
  };

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `https://dev.eves360.ai:3022/api/v1/log`,
    headers: {
      'X-Tenant-Id': '',
      'X-Application': '',
      'X-User-Id': '',
      'xCorrelationId': ''
    },
    data: payload
  };

  try {
    const response = await axios.request(config);
    console.log(response?.data?.message);

    notify.message = response?.data?.message;
    notify.type = 'error';
    toast.notificationService?.notify(notify);

    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export default ErrorLogging;

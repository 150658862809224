import React from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import { Select } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { EvesCheckbox } from '@/common/components/atoms/checkbox/checkbox';
import styles from './styles.module.scss';
const style = {
  display: 'flex',
  minWidth: '206px',
  height: '40px',
  alignItems: 'center',
  borderRadius: '4px',
  background: 'var(--Base-White, #FFF)',
  boxShadow: 'none !important',
  fontFamily: 'Poppins',
  '& .MuiOutlinedInput-notchedOutline': {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Poppins',
    border: 'none',
  },
  '& .MuiSelect-outlined': {
    fontSize: '16px',
    fontWeight: 400,
    color: 'black',
    border: '1px solid #D0D5DD',
    padding: '8px',  
    '@media (max-width: 1440px)': {
      fontSize: '14px',
      padding: '4px',  
    },
    '@media (max-width: 1336px)': {
      fontSize: '12px',
      padding:'4px'
    },
  },
  '@media (max-width: 1440px)': {
    height:'30px' 
  },
};
export default function DemandViewSelect({
  options,
  selected,
  setSelected,
  multiple = false,
  renderText = 'Select',
  sx,
  label=''
}: any) {
  const ITEM_HEIGHT = 52;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      sx: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        boxShadow: 0,
        fontFamily: 'Poppins',
        marginTop: '1px',
        border: '1px solid #EAECF0',
        borderBottom: 'none'
      },
    },
  };

  const getSelectedLabel = (value: string) => {
    const option = options?.find((opt: any) => opt.value === value);
    return option?.label || value;
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (multiple) {
      const valueArray = event.target.value as string;
      const value = valueArray[selected.length === valueArray.length ? valueArray.length : valueArray.length - 1];
      const selectedIndex = selected.filter((item) => item === value);
      let newSelected: any = [];
      if (selectedIndex.length === 0 && valueArray.length !== 0) {
        newSelected = [...selected, value];
      } else {
        newSelected = valueArray;
      }
      setSelected(newSelected);
    } else {
      setSelected(event.target.value);
    }
  };

  return (
    <Grid display={'flex'} gap={'16px'} alignItems={'center'}>
      {label && <div className={styles.labelTextSelect}>{label}</div>}
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple={multiple}
        value={selected.length > 0 ? selected : ['Select']} // Set default value here
        onChange={(e: any) => handleChange(e)}
        renderValue={(selected) => 
          multiple 
            ? selected[0] === 'Select' 
              ? 'Select' 
              : `${renderText} (${selected?.length})`
            : getSelectedLabel(selected as string)
        }
        MenuProps={MenuProps}
        IconComponent={KeyboardArrowDownIcon}
        sx={{ ...style, ...sx }}
      >
        {options?.map((client: any) => (
          <MenuItem
            key={client.value}
            value={client.value}
            sx={{
              display: 'flex',
              padding: '10px !important',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '6px',
              background: 'var(--Base-White, #FFF)',
              boxShadow: 'none !important',
              minWidth: '0px !important',
              borderBottom: '1px solid #D0D5DD'
            }}
          >
            {multiple && <EvesCheckbox sx={{ padding: '0px', margin: '0px', width: '16px', height: '16px', color: '' }} checked={selected?.indexOf(client.value) > -1} />}
            <Typography
              component={'p'}
              sx={{
                color: '#242424',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '1rem',
                fontFamily: 'Poppins !important',
                width: '100%',
                display: 'flex',
                justifyContent: 'start',
                '@media (max-width: 1440px)': {
                  fontSize: '14px',
                },
                '@media (max-width: 1336px)': {
                  fontSize: '12px',
                },
              }}
            >
              {client.label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
}

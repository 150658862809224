/* eslint-disable no-mixed-spaces-and-tabs */
import { Content } from '@/common/components/templates/content/content';
import { DefaultPageSize, filterSelector, StatusCodes } from '@/common/constants/constants';
import { Filters, LookupEntityType } from '@/common/enums/enums';
import { ArrowRight, Close } from '@mui/icons-material';
import { Grid, InputAdornment } from '@mui/material';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { connect } from 'react-redux';
import { ITransactionsHistoryStateModal } from '../../shared/models/history';
import { EvesIconButton } from '@/common/components/atoms/iconButton/iconButton';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import LookupComponent from '@/common/components/lookupComponent';
import { CellStatus } from '@/common/components/organisms/table/dataTableCells';
import { IContextPropsModel, withContext, withUseFormHook } from '@/common/utils/withContext';
import { OWNER_FILTER_ITEMS as Owner } from '@/common/constants/constants';
import FilterSelector from '@/common/components/filterSelector';
import { ReactComponent as SmartCharger } from '@/assets/img/gridify/smartcharger.svg';
import { ReactComponent as EditSVG } from '@/assets/img/gridify/edit.svg';
import { ReactComponent as OcppSVG } from '@/assets/img/gridify/chargerCongiguration/ocpp.svg';
import { ReactComponent as InvalidOcppSVG } from '@/assets/img/gridify/chargerCongiguration/ocppWrong.svg';
import { ReactComponent as Communication } from '@/assets/img/gridify/chargerCongiguration/connection.svg';
import { ReactComponent as SmartReadySVG } from '@/assets/img/gridify/chargerCongiguration/smartReady.svg';
import { ReactComponent as InvalidSmartCharginSVG } from '@/assets/img/gridify/chargerCongiguration/smartReadyWrong.svg';
import { ReactComponent as ValidOCPPSVG } from '@/assets/img/gridify/chargerCongiguration/validocpp.svg';
import { ReactComponent as InvalidOCPPSVG } from '@/assets/img/gridify/chargerCongiguration/invalidocpp.svg';
import { ReactComponent as InfoOCPPSVG } from '@/assets/img/gridify/chargerCongiguration/infoocpp.svg';
import { ReactComponent as ConfigSVG } from '@/assets/img/gridify/chargerCongiguration/config.svg';
import TotalSitesSVG from '@/assets/img/gridify/totalSites.svg';
import TotalSiteAreasSVG from '@/assets/img/gridify/totalSiteAreas.svg';
import CommunicatingSVG from '@/assets/img/gridify/communicating.svg';
import OCCPSmartChargingSVG from '@/assets/img/gridify/occpSmartCharging.svg';
import DemandTracking from '@/assets/img/gridify/demandtracking.svg';
import { useLocation } from 'react-router-dom';
import { columnsType } from '@/common/models/model';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown } from '@mui/icons-material';
import EditChargingStation from '../../../chargingStation/components/chargingStation/components/chargingStationForm';
import TransactionDetails from '@/common/components/templates/transactionDetails';
import { Loader } from '@/common/components/atoms/loader/loader';
import ButtonGroup from '@/common/components/molecules/buttonGroup/buttonGroup';
import { autoRefresh } from '@/common/utils/autoRefreshConfig';
import CommonCardDemandView from '../../shared/components/common-demand-card/common-card';
import { HeirachyTable } from '../../shared/components/heirarchyTable';
import { CustomSwitchComponent } from '../../shared/components/customSwitchComponent';
import SiteAreaForm from '@/modules/organization/components/siteArea/components/siteAreaForm';
import {
  getChargerConfig,
  getChargerConfigSummary,
} from '@/services/chargerConfiguration';
import { updateChargingStationForm, updateOCPPParameters } from '@/services/chargingStations';
import { ChargingStationFormModel } from '@/modules/chargingStation/shared/models/chargingStationForm';
import { formSchema } from '@/modules/shared/schemas/priceFormSchema';
import { handleHttpError } from '@/common/utils/utils';
import { NotifyType } from '@/common/utils/notificationService';
const ChargerConfiguration = (props: IContextPropsModel) => {
  const { t: translate } = useTranslation();
  const location = useLocation();
  const firstParam = location?.search?.split('&')[0];
  const parameterCharger =
    firstParam !== '' ? firstParam?.slice(1).split('=') : [];

  const [displayLoader, setDisplayLoader] = useState(false);
  const startDate = new Date(new Date().getFullYear(), 0, 1);
  startDate.setSeconds(0);
  const endDate = new Date();
  endDate.setSeconds(0);
  endDate.setHours(23);
  endDate.setMinutes(59);

  const [checkdata, setCheckdata] = useState<any>({
  
  });
    const [state, setStateData] = useState({
    connectorID: null,
    defaultIssuer: true,
    isResetBtnDisabled: true,
    issuer: true,
    isTransactionDetailModalOpen: false,
    limit: DefaultPageSize,
    page: 0,
    projectFields: [],
    rows: [],
    mainData: [],
    stats: [],
    search: '',
    selectedChargingStationID: '',
    selectedConnectorID: -1,
    selectedTransactionId: -1,
    selectedRecords: [],
    siteID: '',
    siteAreaID: '',
    sortFields: '-timestamp',
    totalRowsCount: 0,
    userID: '',
    visualTagID: '',
    isChargingStationLookupModalOpen: false,
    chargingStationName:
      parameterCharger?.length > 0 ? parameterCharger[1] : '',
    chargingStationId: parameterCharger?.length > 0 ? parameterCharger[1] : '',
    inactivityStatus: [],
    userName: '',
    showRFIDFilterModal: false,
    showUserLookUpModal: false,
    RFIDFilterValue: '',
    issuerName: '',
    companyName: '',
    siteName: '',
    siteAreaName: '',
    canExport: false,
    canListSiteAreas: false,
    canListSites: false,
    isSiteLookupModalOpen: false,
    isSiteAreaLookupModalOpen: false,
    withSiteArea: true,
    withSite: true,
    withCompany: true,
    withTag: true,
    withCar: true,
    withUser: true,
    statistics: '',
    subStationID: '',
    subStationName: '',
    feederID: '',
    feederName: '',
    transformerID: '',
    transformerName: '',
    isSubStationIdLookupModalOpen: false,
    isFeederIdLookupModalOpen: false,
    isTransformerIdLookupModalOpen: false,
    editChargingStationForm: false,
    onlyRecordCount: true,
    showGraph: true,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    editSiteAreaForm: false,
    siteAreaId: '',
    rowData: [],
    chargerSiteID: '',
    summaryData: {},
  } as ITransactionsHistoryStateModal);
  const [showfilter, setShowfilter] = useState<boolean>(false);
  const defaultSortColumn = '-timestamp';
  const chargingStationLookupRef: any = useRef(null);
  const siteLookupRef: any = useRef(null);
  const siteAreaLookupRef: any = useRef(null);
  const searchBoxRef = useRef<any>();
  const dataTableRef = useRef<any>();
  const notify: NotifyType = {
    message: '',
    type: 'success',
  };
  let isOnClickSiteFilterClear = false;
  let isOnClickSiteAreaFilterClear = false;

  const { refetch: fetchTransactionsHistory } = useQuery(
    'chargerConfig',
    () => {
      return getChargerConfig(getTransactionHistoryParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDisplayLoader(false);
        if (data) {
          const getConvertedResponse = (data) => {
            const finalData = data?.map((site) => {
              return {
                siteName: site?.name,
                id: site?.id,
                childrens: site?.siteArea?.map((siteArea) => {
                  return {
                    siteAreaName: siteArea?.name,
                    id: siteArea?.id,
                    smartCharging: siteArea?.smartCharging,
                    childrens: siteArea?.chargingStations?.map((charger) => {
                      return {
                        chargerName: charger?.id,
                        connectors: charger?.numberOfConnectors || 0,
                        ocpp: charger?.ocpp,
                        excludeFromSmartCharging: charger?.excludeFromSmartCharging,
                        smartReady: charger?.smartReady,
                        tracking: charger?.tracking,
                        validOCPP: charger?.validOCPP,
                        config: charger?.config,
                        lastSeen:charger?.lastSeen,
                        inactive:charger?.inactive,
                        nameSite:site?.name,
                        idSite:site?.id,
                        nameSiteArea:siteArea?.name,
                        idSiteArea:siteArea?.id

                      };
                    }),
                  };
                }),
              };
            });
            return finalData;
          };
          const mainData = getConvertedResponse(data?.result);
          setStateData((currentData) => {
            return {
              ...currentData,
              totalRowsCount: data.count,
              stats: data.stats,
              rows: mainData,
              mainData: mainData,
              projectFields: data.projectFields && [...data.projectFields],
            };
          });
        }
      },
      onError: () => {
        setDisplayLoader(false);
      },
    }
  );

  const { refetch: fetchSummary } = useQuery(
    'chargerConfigSummary',
    () => {
      return getChargerConfigSummary(getTransactionHistoryParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDisplayLoader(false);
        if (data) {
          setStateData((currentData) => {
            return {
              ...currentData,
              summaryData: data?.data,
            };
          });
        }
      },
      onError: () => {
        setDisplayLoader(false);
      },
    }
  );

  const getTransactionHistoryParams = (count = false): any => {
    const TransactionHistoryGetParams: any = {
      Limit: state.limit,
      Issuer: state.issuer,
      WithSiteArea: true,
    };

    if (state.search !== '') {
      TransactionHistoryGetParams['Search'] = state.search;
    }
    if (count) {
      TransactionHistoryGetParams.OnlyRecordCount = true;
    }
    if (state.issuer !== null) {
      TransactionHistoryGetParams['Issuer'] = state.issuer;
    }
    if (state.siteID !== '') {
      TransactionHistoryGetParams['SiteID'] = state.siteID;
    }
    if (state.siteAreaID !== '') {
      TransactionHistoryGetParams['SiteAreaID'] = state.siteAreaID;
    }
    if (state.userID !== '') {
      TransactionHistoryGetParams['UserID'] = state.userID;
    }
    if (state.chargingStationName !== '') {
      TransactionHistoryGetParams['ChargingStationsID'] =
        state.chargingStationName;
    }
    if (state.connectorID && state.connectorID !== '') {
      const connectorIds = state.connectorID.split('|');

      const filteredIds = connectorIds.filter((id) => id !== 'NoSmartCharging');

      if (filteredIds.length > 0) {
        TransactionHistoryGetParams['SmartChargingType'] =
          filteredIds.join('|');
      } else {
        TransactionHistoryGetParams['Enabled'] = true;
      }
    }

    return TransactionHistoryGetParams;
  };

  const ChargerName = (row: any) => {
    const handleCharger = () => {
      setStateData((prev) => {
        return {
          ...prev,
          editChargingStationForm: true,
          chargingStationId: row?.chargerName,
          chargerSiteID: row?.rowIndex,
        };
      });
    };
    return (
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div
          style={{
            color: '#3D3AD3',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => handleCharger()}
        >
          {row?.chargerName}
        </div>
      </div>
    );
  };

  const SiteName = (row: any) => {
    const Icon = row.childrens?.length === 0 ? ArrowRight : ArrowDropDown;
    const handleRowClick = (rowc: any, name: any) => {
      if (name == 'site') {
        const finData: any = state.rows.map((item) => {
          if (item.id === row.id) {
            return {
              ...item,
              childrens:
                row.childrens?.length > 0
                  ? []
                  : state.mainData.filter((itemc) => itemc.id === item.id)[0]
                    ?.childrens,
            };
          } else return item;
        });
        setStateData({ ...state, rows: finData });
      }
    };
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '3px',
        }}
      >
        <Icon
          style={{ display: row.siteName ? '' : 'none', cursor: 'pointer' }}
          onClick={() => handleRowClick(row, 'site')}
        />
        {row?.siteName}
      </div>
    );
  };
  const handleSave = (row: any) => {
    const payload: any = {
      args: {
        key: 'ClockAlignedDataInterval',
        value: 15,
        custom: 'false',
      },
    };

    //TODO: OCPP
    // updateOCPPParameters(row, payload).then((res : any) => {
    //   console.log(res.status);
    //   if (res.status ===( 500 || 'NotSupported') ) {
    //     props.notificationService?.notify({
    //       message: `${translate('chargers.change_params_error')}`,
    //       type: 'error',
    //     });
    //   } else if (res.status ===  'Accepted' ) {
    //     props.notificationService?.notify({
    //       message: `${translate('chargers.change_params_success')}`,
    //       type: 'success',
    //     });
    //   } else if (res.status === 404) {
    //     props.notificationService?.notify({
    //       message: 'please select charging station id',
    //       type: 'error',
    //     });
    //   }
    // });
    props.dialogService?.showConfirm(
    	{
    		title: `${translate('chargers.set_configuration_title')}`,
    		description:`${translate('chargers.set_configuration_confirm').replace(/{{key}}/g, `'${row.key}'`).replace(/{{chargeBoxID}}/g, `'${row}'`)}`,
    		confirmType: 'YES_NO',
    	},
    	(result: any) => {
    		if (result === 'YES') {
    			const payload: any = {
    				args: {
    					key: 'ClockAlignedDataInterval',
    					value:15,
    					custom:  'false',
    				}
    			};
          const payload1: any = {
    				args: {
    					custom: 'false',
              value:'Power.Active.Import',
              key:'MeterValuesAlignedData'
    				}
    			};
    			updateOCPPParameters(row, payload).then(res => {
    				if(res.status === 500){
    					props.notificationService?.notify({
    						message:`${translate('chargers.change_params_error').replace(/{{paramKey}}/g,'ClockAlignedDataInterval').replace(/{chargeBoxID}}/g, `'${row}'`)}`,
    						type: 'error',
    					});
    				}else {
    					props.notificationService?.notify({
    						message: `${translate('chargers.change_params_success').replace(/{{paramKey}}/g,'ClockAlignedDataInterval').replace(/{{chargeBoxID}}/g, `'${row}'`,)}`,
    						type: 'success',
    					});
              fetchTransactionsHistory();
    				}
    			});
          updateOCPPParameters(row, payload1).then(res => {
    				if(res.status === 500){
    					props.notificationService?.notify({
    						message:`${translate('chargers.change_params_error').replace(/{{paramKey}}/g,'ClockAlignedDataInterval').replace(/{chargeBoxID}}/g, `'${row}'`)}`,
    						type: 'error',
    					});
    				}else {
    					props.notificationService?.notify({
    						message: `${translate('chargers.change_params_success').replace(/{{paramKey}}/g,'ClockAlignedDataInterval').replace(/{{chargeBoxID}}/g, `'${row}'`,)}`,
    						type: 'success',
    					});
              fetchTransactionsHistory()
    				}
    			});
    		}
    	}
    );
  };

  const SiteAreaName = (row: any) => {
    const Icon = row.childrens?.length === 0 ? ArrowRight : ArrowDropDown;
    const handleRowClick = (rowc: any, name: any) => {
      if (name == 'siteAreaName') {
        const finData: any = state.rows.map((item) => {
          if (item.id === row.rowIndex) {
            const childrens = item.childrens.map((itemc) => {
              if (itemc.id === row.id) {
                let data: any = [];
                row.childrens?.length > 0
                  ? (data = [])
                  : state.mainData.filter((item) => {
                    if (row.rowIndex === item.id) {
                      data = item.childrens.filter(
                        (itemc) => itemc.id === row.id
                      )[0]?.childrens;
                    }
                  });

                return { ...itemc, childrens: data };
              } else return itemc;
            });

            return { ...item, childrens: childrens };
          } else return item;
        });
        setStateData({ ...state, rows: finData });
      }
    };
    const handleCharger = () => {
      setStateData((currentData) => {
        return {
          ...currentData,
          editSiteAreaForm: true,
          siteAreaId: row?.id,
        };
      });
    };
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '0px',
        }}
      >
        {row?.siteAreaName && (
          <EditSVG
            style={{ cursor: 'pointer' }}
            width={'16px'}
            height={'16px'}
            onClick={() => handleCharger()}
            className='commonimageicons'
          />
        )}
        <Icon
          style={{ display: row.siteAreaName ? '' : 'none', cursor: 'pointer' }}
          onClick={() => handleRowClick(row, 'siteAreaName')}
        />
        {row?.smartCharging?.enabled && (
          <SmartCharger
            width={'20px'}
            height={'20px'}
            style={{ marginRight: '2px' }}
            className='commonimageicons'
          />
        )}
        {row?.siteAreaName}
      </div>
    );
  };

  const columns: columnsType[] = [
    {
      id: 'siteName',
      label: `${translate('sites.title')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 100,
      allowSort: false,
    },
    {
      id: 'siteArea.name',
      label: `${translate('site_areas.title')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 100,
      allowSort: false,
    },
    {
      id: 'chargerName',
      label: `${translate('gridify.demand_view.charger_id')}`,
      minWidth: 60,
      textAlign: 'start',
      maxWidth: 80,
      allowSort: false,
    },
    {
      id: 'connectors',
      label: `${translate('gridify.charger_configuration.connectors')}`,
      minWidth: 40,
      textAlign: 'start',
      maxWidth: 50,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'ocpp',
      label: `${translate('gridify.charger_configuration.OCCP')}`,
      minWidth: 20,
      textAlign: 'start',
      maxWidth: 30,
      allowSort: false,
      projectField: 'communication',
    },
    {
      id: 'commStatus',
      label: `${translate('gridify.charger_configuration.communicationC')}`,
      minWidth: 20,
      textAlign: 'center',
      maxWidth: 30,
      allowSort: false,
      projectField: 'commStatus',
    },
    {
      id: 'included',
      label: `${translate('gridify.charger_configuration.included')}`,
      minWidth: 30,
      textAlign: 'center',
      maxWidth: 40,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'smartReady',
      label: `${translate('gridify.charger_configuration.smart_ready')}`,
      minWidth: 40,
      textAlign: 'start',
      maxWidth: 50,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: '',
      label: '',
      minWidth: 1,
      textAlign: 'start',
      maxWidth: 1,
    },
    {
      id: 'tracking',
      label: `${translate('gridify.charger_configuration.tracking')}`,
      minWidth: 20,
      textAlign: 'start',
      maxWidth: 40,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'validOcpp',
      label: `${translate('gridify.charger_configuration.valid_ocpp')}`,
      minWidth: 30,
      textAlign: 'center',
      maxWidth: 50,
      allowSort: false,
      projectField: 'siteArea.name',
    },
    {
      id: 'config',
      label: `${translate('gridify.charger_configuration.config')}`,
      minWidth: 30,
      textAlign: 'center',
      maxWidth: 40,
      allowSort: false,
      projectField: 'siteArea.name',
    },
  ];

  const onTransactionDetailModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isTransactionDetailModalOpen: false,
      };
    });
  };
  const onSiteFormClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        editSiteAreaForm: false,
      };
    });
  };
  const onTableChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
      };
    });
  };

  const onClickresetFilters = () => {
    chargingStationLookupRef?.current?.setFocus();
    searchBoxRef?.current?.resetSearch();
    setStateData((currentData) => {
      return {
        ...currentData,
        search: '',
        isResetBtnDisabled: true,
        visualTagID: '',
        siteID: '',
        siteAreaID: '',
        userID: '',
        chargingStationId: '',
        isOnClickSiteFilterClear: true,
        chargingStationName: '',
        connectorID: '',
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        siteName: '',
        siteAreaName: '',
        RFIDFilterValue: '',
        userName: '',
        defaultIssuer: true,
        subStationID: '',
        subStationName: '',
        feederID: '',
        feederName: '',
        transformerID: '',
        transformerName: '',
      };
    });
  };

  const onFilterApplied = (searchText: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        search: searchText,
        isResetBtnDisabled:
          searchText === '' &&
          currentData.siteName === '' &&
          currentData.siteAreaName === '' &&
          currentData.chargingStationId === '' &&
          (currentData.defaultIssuer ||
            currentData.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const setSiteFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isSiteLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedSite = () => {
    isOnClickSiteFilterClear = true;
    siteLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteID: '',
        siteName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteAreaID &&
          !state.chargingStationId &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const resetSiteFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
      };
    });
  };

  const onSiteSelect = (sites: any) => {
    const siteName =
      sites?.length > 1
        ? `${sites[0].name} (+${sites?.length - 1})`
        : sites?.length && sites[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
        siteID: sites.map((site: any) => site.id).join('|'),
        siteName: siteName,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetSiteAreaFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
      };
    });
  };

  const onSiteAreaSelect = (siteareas: any) => {
    const siteAreaName =
      siteareas?.length > 1
        ? `${siteareas[0].name} (+${siteareas?.length - 1})`
        : siteareas?.length && siteareas[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
        siteAreaID: siteareas.map((siteArea: any) => siteArea.id).join('|'),
        siteAreaName: siteAreaName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setSiteAreaFilter = () => {
    if (!isOnClickSiteAreaFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isSiteAreaLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteAreaFilterClear = false;
  };

  const onClearSelectedSiteArea = () => {
    isOnClickSiteAreaFilterClear = true;
    siteAreaLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteAreaID: '',
        siteAreaName: '',
        isSiteAreaLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteID &&
          !state.chargingStationId &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  useEffect(() => {
    fetchTransactionsHistory();
    fetchSummary();
 


  }, [
    state.limit,
    state.page,
    state.sortFields,
    state.chargingStationId,
    state.userID,
    state.visualTagID,
    state.issuerName,
    state.siteID,
    state.siteAreaID,
    state.inactivityStatus,
    state.search,
    state.connectorID,
    state.startDate,
    state.endDate,
    state.subStationID,
    state.feederID,
    state.transformerID,
    location,
  ]);

  const resetChargingPlanFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        chargingStationID: '',
        isChargingStationLookupModalOpen: false,
      };
    });
  };

  const onChargingStationSelect = (chargingStations: any) => {
    const chargingStationName =
      chargingStations?.length > 1
        ? `${chargingStations[0].id} (+${chargingStations.length - 1})`
        : chargingStations?.length && chargingStations[0].id;

    setStateData((currentData) => {
      return {
        ...currentData,
        isChargingStationLookupModalOpen: false,
        chargingStationId: chargingStations
          .map((chargingStation: any) => chargingStation.id)
          .join('|'),
        chargingStationName: chargingStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const setChargingStationFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isChargingStationLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedChargingStation = () => {
    isOnClickSiteFilterClear = true;
    chargingStationLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteID &&
          !state.siteAreaID &&
          (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()) &&
          !state.visualTagID &&
          !state.userID &&
          state.inactivityStatus.length == 0,
      };
    });
  };

  const onUserFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showUserLookUpModal: false,
      };
    });
  };

  const getFormatedRFIDCard = (tag: any) => {
    if (tag.length > 1) {
      return `${tag[0].visualID} (+ ${tag.length - 1})`;
    } else {
      return `${tag[0].visualID}`;
    }
  };

  const onUserSelected = (users: any) => {
    const userName =
      users?.length > 1
        ? `${users[0].firstName} ${users[0].name} (+${users?.length - 1})`
        : users?.length && `${users[0].firstName} ${users[0].name}`;
    setStateData((currentDate) => {
      return {
        ...currentDate,
        userID: users.map((user: any) => user.id).join('|'),
        userName: userName,
        showUserLookUpModal: false,
        isResetBtnDisabled: false,
      };
    });
  };
  const onRFIDFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showRFIDFilterModal: false,
      };
    });
  };

  const onRFIDCardSelected = (selectedTag: any) => {
    const sortedObj = Object.values(selectedTag).sort((a: any, b: any) =>
      a.id > b.id ? 1 : -1
    );
    const selectedItems = sortedObj
      ?.map((sessionTag: any) => sessionTag.visualID)
      .join('|');
    setStateData((currentData) => {
      return {
        ...currentData,
        visualTagID: selectedItems,
        RFIDFilterValue: getFormatedRFIDCard(sortedObj),
        showRFIDFilterModal: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const StatusCell = (row: any) => {
    return (
      <CellStatus
        label={
          row.active
            ? `${translate('tags.activated')}`
            : `${translate('tags.deactivated')}`
        }
        type={row.active ? 'Available' : 'Faulted'}
      />
    );
  };

  const onConnectorSelect = (connectorID: string[]) => {
    const filteredItems = connectorID?.map((item) => {
      return filterSelector[Filters.SMART_CHARGING].value[item];
    });
    const invoiceStatus = Object.keys(
      filterSelector[Filters.SMART_CHARGING].value
    ).map((key) => key.toLowerCase().replace(' ', ''));
    const sortedValue = filteredItems.sort(
      (a, b) => invoiceStatus.indexOf(a) - invoiceStatus.indexOf(b)
    );
    const updatedValue = sortedValue.join('|');

    setStateData((currentData) => {
      return {
        ...currentData,
        connectorID: updatedValue,
        isResetBtnDisabled: false,
      };
    });
    if (connectorID.length === 0) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isResetBtnDisabled:
            !state.search &&
            !state.siteID &&
            !state.siteAreaID &&
            !state.chargingStationId &&
            (state.defaultIssuer || state.issuerName === Owner.CurrentOrgAPI) &&
            (state.startDate.toString() == startDate.toISOString() ||
              state.endDate.toString() == endDate.toISOString()) &&
            !state.visualTagID &&
            !state.userID &&
            state.inactivityStatus.length == 0,
        };
      });
    }
  };
  const OcppColumn = (row) => {
    return Object.keys(row).includes('chargerName') ? (
      <div style={{ cursor: 'no-drop' }}>
        {row.ocpp ? (
          <OcppSVG className='chargerConfigurationImage' />
        ) : (
          <InvalidOcppSVG className='chargerConfigurationImage' />
        )}
      </div>
    ) : (
      <></>
    );
  };
  const CommunicationColumn = (row) => {
    return Object.keys(row).includes('lastSeen') ?(
      <div
        style={{ display: 'flex', justifyContent: 'center', cursor: 'no-drop' }}
      >
   {!row.inactive ? (
  <Communication className='chargerConfigurationImage' />
) : (
  <Communication
    fill={'#F04438'}
    className='chargerConfigurationImage'
  />
)}

      </div>
    ): (
      <></>
    );
  
  };
  const handleChecked = (event, rowId?:any, type?:any,nameSiteArea?:any,idSiteArea?:any) => {
    const updatedValue = event.target.checked;
 

    const payload:any = {
      id:rowId,
      [type]:type==='excludeFromSmartCharging'? !updatedValue:updatedValue,
      siteArea:nameSiteArea,
      siteAreaID:idSiteArea
    };
    setCheckdata(payload)

    editChargingStationForm(payload)
  };
  const { mutate: editChargingStationForm } = useMutation(
    (chargingStationFormData:any) => {
      return updateChargingStationForm(chargingStationFormData?.id, chargingStationFormData);
    },
    {
      onSuccess: (data: any) => {
        if (data.status === 'Success') {
          notify.message = `${translate(
            'chargers.change_config_success'
          ).replace(/{{chargeBoxID}}/g, `'${(checkdata as any)?.id}'`)}`;
          notify.type = 'success';
          fetchTransactionsHistory()
          props.notificationService?.notify(notify);
        } else if (data.status === StatusCodes.NOT_FOUND) {
          notify.message = `${translate('chargers.change_config_error')}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
        } else if (
          data.status ===
          StatusCodes.THREE_PHASE_CHARGER_ON_SINGLE_PHASE_SITE_AREA
        ) {
          notify.message = `${translate('chargers.change_config_phase_error')}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
        } else if (data.status === StatusCodes.CHARGE_POINT_NOT_VALID) {
          notify.message = `${translate(
            'chargers.charge_point_connectors_error'
          )}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
        } else if (data.status === StatusCodes.FEATURE_NOT_SUPPORTED_ERROR) {
          notify.message = `${translate('chargers.update_public_cs_error')}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
        } else {
          notify.message = `${translate('chargers.change_config_error')}`;
          notify.type = 'error';
          props.notificationService?.notify(notify);
        }
      },
      onError: (error) => {
        const errorNotify = handleHttpError(
          error,
          `${translate('chargers.change_config_error')}`
        );
        props.notificationService?.notify(errorNotify);
      },
    }
  );

  

  
  const IncludeColumn = (row) => {
    return Object.keys(row).includes('excludeFromSmartCharging') ? (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CustomSwitchComponent
          handleChecked={(event) => handleChecked(event, row?.chargerName,'excludeFromSmartCharging',row?.nameSiteArea,row?.idSiteArea)}
          check={
            !row.excludeFromSmartCharging
          }
        />
      </div>
    ) : (
      <></>
    );
  };
  const SmartReadyColumn = (row) => {
    return Object.keys(row).includes('chargerName') ? (
      <div
        style={{ display: 'flex', justifyContent: 'center', cursor: 'no-drop' }}
      >
        {row.smartReady ? (
          <SmartReadySVG className='chargerConfigurationImage' />
        ) : (
          <InvalidSmartCharginSVG className='chargerConfigurationImage' />
        )}
      </div>
    ) : (
      <></>
    );
  };
  const TrackingColumn = (row) => {

    return Object.keys(row).includes('tracking') ? (
      <div style={{  }}>
 <CustomSwitchComponent
          handleChecked={(event) => handleChecked(event, row?.chargerName,'tracking',row?.nameSiteArea,row?.idSiteArea)}
          check={
            row.tracking
          }
        />      </div>
    ) : (
      <></>
    );
  };
  const ValidOcppColumn = (row:any) => {
    if (!row?.chargerName) return null;
  
    const renderIcon = () => {
      if (!row?.inactive && row?.validOCPP) return <ValidOCPPSVG className="chargerConfigurationImage" />;
      if (row?.inactive && row?.validOCPP) return <InfoOCPPSVG className="chargerConfigurationImage" />;
      if (row?.inactive && !row?.validOCPP) return <InfoOCPPSVG className="chargerConfigurationImage" />;
      if (!row?.inactive && !row?.validOCPP) return <InvalidOCPPSVG className="chargerConfigurationImage" />;
      return null;
    };
  
    return Object.keys(row).includes('validOCPP') ? (
      <div style={{ display: 'flex', justifyContent: 'center', cursor: 'no-drop' }}>
        {renderIcon()}
      </div>
    ) : (
      <></>
    );
  };
  

  const ConfigColumn = (row) => {
    return Object.keys(row).includes('chargerName') ? (
      <div
        style={{ display: 'flex', justifyContent: 'center',cursor:'pointer' }}       onClick={()=>handleSave(row?.chargerName)}  >
        { 
          <ConfigSVG className='chargerConfigurationImage' />
         }
      </div>
    ) : (
      <></>
    );
  };

  const resetSubStationIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isSubStationIdLookupModalOpen: false };
    });
  };
  const onSubStationIdSelect = (substations: any) => {
    const subStationName =
      substations?.length > 1
        ? `${substations[0].name} (+${substations?.length - 1})`
        : substations?.length && substations[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSubStationIdLookupModalOpen: false,
        subStationID: substations
          .map((subStation: any) => subStation.name)
          .join('|'),
        subStationName: subStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetFeederIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isFeederIdLookupModalOpen: false };
    });
  };
  const onFeederIdSelect = (feeders: any) => {
    const feederName =
      feeders?.length > 1
        ? `${feeders[0].name} (+${feeders?.length - 1})`
        : feeders?.length && feeders[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isFeederIdLookupModalOpen: false,
        feederID: feeders.map((feeder: any) => feeder.name).join('|'),
        feederName: feederName,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetTransformerIdFilter = () => {
    setStateData((currentData) => {
      return { ...currentData, isTransformerIdLookupModalOpen: false };
    });
  };
  const onTransformerIdSelect = (transformer: any) => {
    const feederName =
      transformer?.length > 1
        ? `${transformer[0].name} (+${transformer?.length - 1})`
        : transformer?.length && transformer[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isTransformerIdLookupModalOpen: false,
        transformerID: transformer.map((trans: any) => trans.name).join('|'),
        transformerName: feederName,
        isResetBtnDisabled: false,
      };
    });
  };

  const onFormClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        editChargingStationForm: false,
      };
    });
  };
  const resetSiteAreaForm = (val: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        siteAreaId: val,
      };
    });
  };
  const alignStyle: CSSProperties = { alignItems: 'flex-end !important' };


  return (
    <>
      {state.editChargingStationForm && (
        <EditChargingStation
          onFormClose={onFormClose}
          chargingStationId={state.chargingStationId}
          siteId={state.chargerSiteID}
          limit={state.limit}
          fetchAllChargingStations={() => {
            fetchTransactionsHistory()
          }}
          canUpdateChargingStation={false}
          editChargingStationForm={state.editChargingStationForm}
        />
      )}
      {state.editSiteAreaForm && (
        <SiteAreaForm
          siteAreaId={state.siteAreaId}
          openSiteArea={state.editSiteAreaForm}
          onFormClose={onSiteFormClose}
          pageLimit={DefaultPageSize}
          fetchAllSiteAreas={() => {
            /* */
          }}
          resetSiteAreaForm={resetSiteAreaForm}
          canCreate={false}
          isPowerLimit={true}
        />
      )}
      {<Loader showLoader={displayLoader} />}
      {state.showUserLookUpModal && (
        <LookupComponent
          title={`${translate('users.select_users')}`}
          showLookupModal={state.showUserLookUpModal}
          onModalClose={onUserFilterModalClose}
          selectionMode='multiple'
          entityType={LookupEntityType.SESSION_USER}
          params={[{ key: 'Issuer', value: true }]}
          onSelect={onUserSelected}
          defaultSortColumn='name'
          data-cy='session-select-user-lookup'
        />
      )}

      {state.showRFIDFilterModal && (
        <LookupComponent
          data-cy='RFIDLookUpModal'
          title={`${translate('tags.select_tags')}`}
          showLookupModal={state.showRFIDFilterModal}
          onModalClose={onRFIDFilterModalClose}
          selectionMode='multiple'
          entityType={LookupEntityType.SESSION_RFID_TAG}
          tableChildren={<StatusCell slot='statusCustomValue' />}
          params={
            state.userID
              ? [
                { key: 'Issuer', value: true },
                { key: 'WithUser', value: true },
                { key: 'UserID', value: state.userID },
              ]
              : [
                { key: 'Issuer', value: true },
                { key: 'WithUser', value: true },
              ]
          }
          onSelect={onRFIDCardSelected}
          defaultSortColumn='id'
        />
      )}

      <Content
        contentTop={
          <Grid>
            <Grid
              container
              item
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Grid item xs={10} lg={9} sm={6} md={8} xl={9}>
                {showfilter && (
                  <Grid
                    container
                    item
                    spacing={2}
                    rowSpacing={1}
                    sx={alignStyle}
                  >
                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                      <EvesTextbox
                        value={state.siteName}
                        id='history-standard-search'
                        label={`${translate('general.search_site')}`}
                        data-cy='select-site'
                        type='text'
                        variant='standard'
                        onClick={setSiteFilter}
                        onChange={onFilterApplied}
                        ref={siteLookupRef}
                        isFilter={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <EvesIconButton
                                icon={Close}
                                fontSize={15}
                                onClick={onClearSelectedSite}
                              ></EvesIconButton>
                            </InputAdornment>
                          ),
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                      <EvesTextbox
                        value={state.siteAreaName}
                        id='inProgress-standard-search'
                        label={`${translate('site_areas.titles')}`}
                        data-cy='select-site-area'
                        type='text'
                        variant='standard'
                        onClick={setSiteAreaFilter}
                        onChange={onFilterApplied}
                        isFilter={true}
                        ref={siteAreaLookupRef}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <EvesIconButton
                                icon={Close}
                                fontSize={15}
                                onClick={onClearSelectedSiteArea}
                              ></EvesIconButton>
                            </InputAdornment>
                          ),
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                      <EvesTextbox
                        value={state.chargingStationName}
                        style={{
                          width: '100%',
                          cursor: 'pointer',
                        }}
                        id='sessions-charging-station-lookup'
                        label={`${translate('chargers.chargers')}`}
                        type='text'
                        variant='standard'
                        data-cy='sessions-charging-station-lookup'
                        onClick={setChargingStationFilter}
                        onChange={onFilterApplied}
                        ref={chargingStationLookupRef}
                        isFilter={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <EvesIconButton
                                icon={Close}
                                fontSize={15}
                                onClick={onClearSelectedChargingStation}
                              ></EvesIconButton>
                            </InputAdornment>
                          ),
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                      <FilterSelector
                        filterOption={Filters.SMART_CHARGING}
                        defaultIssuer={state.defaultIssuer}
                        onChange={onConnectorSelect}
                        isResetBtnDisabled={state.isResetBtnDisabled}
                        data-cy='connector-filter'
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item gap={'10px'} mt={1} xs={2} sm={6} md={4} lg={3} xl={3}>
                <ButtonGroup
                  {...{
                    showfilter,
                    setShowfilter,
                    resetFilters: onClickresetFilters,
                    state,
                    multiple: true,
                    isConfig: true,
                    handleConfig: handleSave,
                    hideLabel: false,
                    autoRefreshStatus:false,
                    // intervalsAndFunctions: [
                    //   {
                    //     interval: autoRefresh.sessions.IN_HISTORY,
                    //     func: fetchTransactionsHistory,
                    //   },
                    //   {
                    //     interval: autoRefresh.sessions.IN_HISTORY,
                    //     func: fetchSummary,
                    //   },
                    // ],
                    // onClickExport: exportSession,
                    // isExport: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={'12px'} xs={12} mb={'6px'} mt={'1px'}>
  <Grid item xs={1.7}>
    <CommonCardDemandView
      img={TotalSitesSVG}
      historyTab={true}
      headerText='Total Sites'
      footerText={state.summaryData?.totalSites || 0}
      demandView={false}
    />
  </Grid>
  <Grid item xs={3}>
    <CommonCardDemandView
      img={TotalSiteAreasSVG}
      historyTab={true}
      smartCharging={state.summaryData?.totalSitearea?.demandManagment || 0}
      orgSites={state.summaryData.totalSitearea?.loadBalance || 0}
      headerText='Total Site Areas'
      footerText={state.summaryData.totalSitearea?.count ||0}
      demandView={false}
    />
  </Grid>
  <Grid item xs={2.5}>
    <CommonCardDemandView
      img={CommunicatingSVG}
      historyTab={true}
      headerText='Communicating'
      numerator={state.summaryData.chargerStatus?.connected || 0}
      denominator={state.summaryData.chargerStatus?.disconnected || 0}
      demandView={false}
    />
  </Grid>
  <Grid item xs={2.5}>
    <CommonCardDemandView
      img={OCCPSmartChargingSVG}
      historyTab={true}
      headerText='OCPP smart charging'
      numerator={state.summaryData?.ocppSmartCharging || 0}
      denominator={state.summaryData.totalChargers || 0}
      demandView={false}
    />
  </Grid>
  <Grid item xs={2.3}>
    <CommonCardDemandView
      img={DemandTracking}
      historyTab={true}
      headerText='Demand Tracking'
      numerator={state.summaryData.demandTracking || 0}
      denominator={state.summaryData.totalChargers || 0}
      demandView={false}
    />
  </Grid>
</Grid>

          </Grid>
        }
        contentBody={
          <Grid
            sx={{
              borderRadius: '8px',
              border:
                '1px solid var(--Foundation-Base-on-Light-base-on-light-3, #F5F5F5)',
              background: 'var(--Base-White, #FFF)',
              height: 'calc(100vh - 290px)',
            }}
          >
            <Grid data-cy={'demand-view-table'}>
              <HeirachyTable
                ref={dataTableRef}
                className='transaction-history-table'
                rows={state.rows}
                columns={columns}
                totalRowsCount={state.totalRowsCount}
                onChange={onTableChange}
                defaultSortField={defaultSortColumn}
                page={'Demand View'}
                // tablePagination={true}
                defaultSortDescending={true}
                height={{
                  height: 'calc(100vh - 340px)',
                  '@media (max-width: 1720px)': {
                    height: 'calc(100vh - 400px)',
                  },
                  '@media (max-width: 1500px)': {
                    height: 'calc(100vh - 400px)',
                  },
                  '@media (max-width: 1400px)': {
                    height: 'calc(100vh - 180px)',
                  },
                  '@media (max-width: 1300px)': {
                    height: 'calc(100vh - 300px)',
                  },
                  '@media (max-width: 1200px)': {
                    height: 'calc(100vh - 300px)',
                  },
                }}
                data-cy='demand-view-table'
              >
                <SiteName slot='siteName' />
                <SiteAreaName slot='siteArea.name' />
                <ChargerName slot='chargerName' />
                <OcppColumn slot='ocpp' />
                <CommunicationColumn slot='commStatus' />
                <IncludeColumn slot='included' />
                <SmartReadyColumn slot='smartReady' />
                <TrackingColumn slot='tracking' />
                <ValidOcppColumn slot='validOcpp' />
                <ConfigColumn slot='config' />
              </HeirachyTable>
            </Grid>
          </Grid>
        }
      ></Content>
      {state.isChargingStationLookupModalOpen && (
        <LookupComponent
          data-cy='sessions-charging-plan-lookup'
          title={`${translate('chargers.select_chargers')}`}
          showLookupModal={state.isChargingStationLookupModalOpen}
          onModalClose={resetChargingPlanFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.CHARGINGSTATION}
          params={
            state.siteAreaID
              ? [
                { key: 'Issuer', value: state.issuer },
                { key: 'SiteAreaID', value: state.siteAreaID },
              ]
              : [{ key: 'Issuer', value: state.issuer }]
          }
          onSelect={onChargingStationSelect}
          defaultSortColumn='id'
        />
      )}
      {state.isSiteLookupModalOpen && (
        <LookupComponent
          title={`${translate('sites.select_sites')}`}
          showLookupModal={state.isSiteLookupModalOpen}
          onModalClose={resetSiteFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SITE}
          params={[{ key: 'Issuer', value: state.issuer }]}
          onSelect={onSiteSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isSiteAreaLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_site_areas')}`}
          showLookupModal={state.isSiteAreaLookupModalOpen}
          onModalClose={resetSiteAreaFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SITEAREA}
          params={[
            { key: 'Issuer', value: null },
            { key: 'SiteID', value: state.siteID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onSiteAreaSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isTransactionDetailModalOpen && (
        <TransactionDetails
          transactionId={state.selectedTransactionId}
          chargingStationId={state.selectedChargingStationID}
          connectorId={state.selectedConnectorID}
          onClose={onTransactionDetailModalClose}
          withStopedBy={true}
          popupOpen={state.isTransactionDetailModalOpen}
        />
      )}
      {state.isSubStationIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_substation_id')}`}
          showLookupModal={state.isSubStationIdLookupModalOpen}
          onModalClose={resetSubStationIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SUBSTATIONID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'SubStationID', value: state.subStationID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onSubStationIdSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isFeederIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_feeder_id')}`}
          showLookupModal={state.isFeederIdLookupModalOpen}
          onModalClose={resetFeederIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.FEEDERID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'FeederID', value: state.feederID || null },
            { key: 'WithSite', value: true },
            { key: 'SubStationID', value: state.subStationID || null },
          ]}
          onSelect={onFeederIdSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isTransformerIdLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_transformer_id')}`}
          showLookupModal={state.isTransformerIdLookupModalOpen}
          onModalClose={resetTransformerIdFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.TRANSFORMERID}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'TransformerID', value: state.transformerID || null },
            { key: 'WithSite', value: true },
            { key: 'FeederID', value: state.feederID || null },
            { key: 'SubStationID', value: state.subStationID || null },
            { key: 'FeederID', value: state.feederID || null },
          ]}
          onSelect={onTransformerIdSelect}
          defaultSortColumn='name'
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userInfo: state.userContext.userInfo,
});

export default connect(mapStateToProps)(
  withContext(
    withUseFormHook(ChargerConfiguration, {
      schema: formSchema,
      defaultValues: { ...new ChargingStationFormModel() },
    })
  )
);

import { EvesIconButton } from '@/common/components/atoms/iconButton/iconButton';
import { EvesTextbox } from '@/common/components/atoms/textbox/textbox';
import LookupComponent from '@/common/components/lookupComponent';
import ErrorDetailModal from '@/common/components/molecules/errorDetail';
import { ITableActionMenuModel } from '@/common/components/organisms/table/actionMenu';
import DataTable from '@/common/components/organisms/table/dataTable';
import { Content } from '@/common/components/templates/content/content';
import {
  DefaultPageSize,
  TransactionInErrorTypeDetail,
  filterSelector,
} from '@/common/constants/constants';
import {
  Filters,
  TenantComponents,
  TransactionInErrorType,
  LookupEntityType,
} from '@/common/enums/enums';
import {
  canListCars,
  canListUsers,
  canUpdateCar,
  isActive,
  isAdmin,
} from '@/common/utils/utils';
import { TransactionsInErrorModal } from '@/services/models/session';
import { getTransactionsInError } from '@/services/session';
import { InfoOutlined, OpenInNew, RemoveRedEye } from '@mui/icons-material';
import { Grid, InputAdornment } from '@mui/material';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { ITransactionsInErrorStateModal } from '../../shared/models/inError';
import FilterSelector from '@/common/components/filterSelector';
import { SearchBox } from '@/common/components/searchBox';
import { Close } from '@mui/icons-material';
import TransactionDetails from '@/common/components/templates/transactionDetails';
import { columnsType } from '@/common/models/model';
import { handleDeleteTransactions } from '../../shared/sessions';
import { IContextPropsModel, withContext } from '@/common/utils/withContext';
import DatetimeRangePicker from '@/common/components/atoms/datetimeRangePicker/datetimeRangePicker';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '@/common/components/molecules/buttonGroup/buttonGroup';

const TrasactionsInError = (props: IContextPropsModel) => {
  const { t: translate } = useTranslation();
  const startDate = new Date(new Date().getFullYear(), 0, 1);
  startDate.setSeconds(0);
  const endDate = new Date();
  const [showfilter, setShowfilter] = useState<boolean>(false);
  endDate.setSeconds(0);
  endDate.setHours(23);
  endDate.setMinutes(59);
  const [state, setStateData] = useState({
    connectorID: null,
    errorType: '',
    isErrorDetailModalOpen: false,
    isResetBtnDisabled: true,
    isTransactionDetailModalOpen: false,
    limit: DefaultPageSize,
    page: 0,
    projectFields: [],
    rows: [],
    search: '',
    selectedChargingStationID: '',
    selectedConnectorID: -1,
    selectedTransactionId: -1,
    selectedRecords: [],
    siteID: '',
    siteAreaID: '',
    siteName: '',
    siteAreaName: '',
    sortFields: '-timestamp',
    totalRowsCount: 0,
    transactionErrorCode: '',
    userID: '',
    isChargingStationLookupModalOpen: false,
    chargingStationName: '',
    chargingStationId: '',
    showUserLookUpModal: false,
    userName: '',
    canListSiteAreas: false,
    canListSites: false,
    isSiteLookupModalOpen: false,
    isSiteAreaLookupModalOpen: false,
    issuer: true,
    errorTypes: [],
    defaultIssuer: true,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  } as ITransactionsInErrorStateModal);
  const isUserAdmin = isAdmin();
  const isUserCanListUsers = canListUsers();
  const isUserCanListCars = canListCars();
  const isUserCanUpdateCar = canUpdateCar();
  const defaultSortColumn = '-timestamp';
  const chargingStationLookupRef: any = useRef(null);
  let isOnClickSiteFilterClear = false;
  const siteLookupRef: any = useRef(null);
  const siteAreaLookupRef: any = useRef(null);
  let isOnClickSiteAreaFilterClear = false;
  const UserLookupRef: any = useRef(null);
  const dataTableRef = useRef<any>();
  const isOrganizationActive = isActive(TenantComponents.ORGANIZATION);
  const searchBoxRef = useRef<any>();

  const { refetch: fetchTransactionsInError } = useQuery(
    'TransactionInError',
    () => {
      return getTransactionsInError(getTransactionHistoryParams());
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        if (data) {
          const canListSites = data.canListSites;
          const canListSiteAreas = data.canListSiteAreas;
          const transactionsData = data.result.map(
            (d: any) => new TransactionsInErrorModal(d)
          );
          setStateData((currentData) => {
            return {
              ...currentData,
              rows: transactionsData,
              totalRowsCount: data.count,
              canListSiteAreas,
              canListSites,
              projectFields: data.projectFields && [...data.projectFields],
            };
          });
        }
      },
    }
  );

  const getTransactionHistoryParams = (): any => {
    const TransactionInErrorGetParams: any = {
      Limit: state.limit,
      Page: state.page + 1,
      SortFields: state.sortFields,
      ChargingStationID: state.chargingStationId,
    };

    TransactionInErrorGetParams.StartDateTime = state.startDate;
    TransactionInErrorGetParams.EndDateTime = state.endDate;

    if (state.search !== '') {
      TransactionInErrorGetParams['Search'] = state.search;
    }
    if (state.siteID !== '') {
      TransactionInErrorGetParams['SiteID'] = state.siteID;
    }
    if (state.siteAreaID !== '') {
      TransactionInErrorGetParams['SiteAreaID'] = state.siteAreaID;
    }
    if (state.userID !== '') {
      TransactionInErrorGetParams['UserID'] = state.userID;
    }
    if (state.errorType !== '') {
      TransactionInErrorGetParams['ErrorType'] = state.errorType;
    }
    if (state.connectorID !== -1) {
      TransactionInErrorGetParams['ConnectorID'] = state.connectorID;
    }
    if (state.errorTypes.length > 0) {
      let errorTypes: string = state.errorTypes[0];
      for (let i = 1; i < state.errorTypes.length; i++)
        errorTypes += '|' + state.errorTypes[i];
      TransactionInErrorGetParams['ErrorType'] = errorTypes;
    }
    return TransactionInErrorGetParams;
  };

  const buildTableColumns = (): columnsType[] => {
    const columns: columnsType[] = [
      {
        id: 'actions',
        label: `${translate('general.actions')}`,
        minWidth: 90,
        textAlign: 'center',
        maxWidth: 170,
        allowSort: false,
        projectField: 'action',
      },
    ];

    if (isUserAdmin) {
      columns.push({
        id: 'id',
        label: `${translate('transactions.id')}`,
        minWidth: 60,
        textAlign: 'left',
        maxWidth: 150,
        allowSort: false,
        projectField: 'id',
      });
    }

    columns.push(
      {
        id: 'timestampDateFormated',
        label: `${translate('transactions.started_at')}`,
        minWidth: 60,
        textAlign: 'left',
        maxWidth: 150,
        allowSort: true,
        sortKey: 'timestamp',
        projectField: 'timestamp',
      },
      {
        id: 'chargeBoxID',
        label: `${translate('transactions.charging_station')}`,
        minWidth: 60,
        textAlign: 'left',
        maxWidth: 200,
        allowSort: true,
        sortKey: 'chargeBoxID',
        projectField: 'chargeBoxID',
      },
      {
        id: 'getConnectorLetter',
        label: `${translate('chargers.connector')}`,
        minWidth: 60,
        textAlign: 'center',
        maxWidth: 150,
        allowSort: false,
        projectField: 'connectorId',
      },
      {
        id: 'errorDetail',
        label: `${translate('errors.details')}`,
        minWidth: 80,
        allowSort: false,
        textAlign: 'center',
        maxWidth: 210,
        projectField: 'errorCodeDetails',
      },
      {
        id: 'errorCode',
        label: `${translate('errors.title')}`,
        minWidth: 150,
        allowSort: true,
        sortKey: 'errorCode',
        textAlign: 'left',
        maxWidth: 210,
        projectField: 'errorCode',
      },
      {
        id: 'getStopTotalDuration',
        label: `${translate('transactions.duration')}`,
        minWidth: 60,
        textAlign: 'left',
        maxWidth: 150,
        allowSort: true,
        sortKey: 'stop.totalDurationSecs',
        projectField: 'stop.totalDurationSecs',
      },
      {
        id: 'getStopTotalConsumptionWh',
        label: `${translate('transactions.consumption')}`,
        minWidth: 60,
        textAlign: 'left',
        maxWidth: 150,
        allowSort: false,
        projectField: 'stop.totalConsumptionWh',
      },
      {
        id: 'getStopStateOfCharge',
        label: `${translate('transactions.state_of_charge')}`,
        minWidth: 60,
        textAlign: 'left',
        maxWidth: 150,
        allowSort: false,
        projectField: 'stateOfCharge',
      }
    );

    if (isUserCanListUsers) {
      columns.push(
        {
          id: 'userFullName',
          label: `${translate('transactions.user')}`,
          minWidth: 60,
          textAlign: 'left',
          maxWidth: 190,
          allowSort: false,
          projectField: 'user',
        },
        {
          id: 'tagID',
          label: `${translate('tags.id')}`,
          minWidth: 60,
          textAlign: 'left',
          maxWidth: 150,
          allowSort: false,
          projectField: 'tagID',
        }
      );
    }

    if (isActive(TenantComponents.CAR) && isUserCanListCars) {
      columns.push({
        id: 'getCarCatalog',
        label: `${translate('car.title')}`,
        minWidth: 60,
        textAlign: 'center',
        maxWidth: 150,
        allowSort: true,
        sortKey: 'carCatalog',
        projectField: 'carCatalog',
      });
    }

    if (isActive(TenantComponents.CAR) && isUserCanUpdateCar) {
      columns.push({
        id: 'carLicensePlate',
        label: `${translate('cars.license_plate')}`,
        minWidth: 60,
        textAlign: 'center',
        maxWidth: 150,
        allowSort: true,
        sortKey: 'car.licensePlate',
        projectField: 'car.licensePlate',
      });
    }
    return columns;
  };

  const onTableChange = (params: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        sortFields: params.sortFields,
      };
    });
  };

  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: newPage,
      };
    });
  };

  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        page: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const onClickTransactionDetailModal = (record: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        selectedConnectorID: record.connectorId,
        selectedTransactionId: record.id,
        selectedChargingStationID: record.chargeBoxID,
        isTransactionDetailModalOpen: true,
      };
    });
  };

  const onTransactionDetailModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isTransactionDetailModalOpen: false,
      };
    });
  };

  const primaryActions: ITableActionMenuModel[] = [
    {
      title: () => `${translate('general.tooltips.view')}`,
      icon: () => RemoveRedEye,
      iconColor: 'primary',
      onClick: onClickTransactionDetailModal,
      'data-cy': 'transactions-view-action',
    },
  ];

  const secondaryActions: ITableActionMenuModel[] = [
    {
      title: () => `${translate('logs.redirect')}`,
      icon: () => OpenInNew,
      iconColor: 'primary',
      onClick: (record: any) => {
        window.open(
          `#/logs?ChargingStationID=${record.chargeBoxID}&StartDateTime=${record.timestamp}&LogLevel=I`,
          '_blank'
        );
      },
      visible: () => true,
      'data-cy': 'navigate-to-logs',
    },
  ];

  const onClickresetFilters = () => {
    searchBoxRef?.current?.resetSearch();
    chargingStationLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        isResetBtnDisabled: true,
        visualTagID: '',
        siteID: '',
        siteAreaID: '',
        userID: '',
        chargingStationId: '',
        isOnClickSiteFilterClear: true,
        chargingStationName: '',
        connectorID: null,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        siteAreaName: '',
        siteName: '',
        userName: '',
        search: '',
      };
    });
  };

  const setErrorDetailModalOpen = (errorCode: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        transactionErrorCode: errorCode,
        isErrorDetailModalOpen: true,
      };
    });
  };

  const OnErrorDetailModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isErrorDetailModalOpen: false,
      };
    });
  };

  const ErrorCodeDetailsCell = (row: any): JSX.Element => {
    return (
      <>
        <EvesIconButton
          icon={InfoOutlined}
          color='error'
          onClick={() => setErrorDetailModalOpen(row.errorCode)}
        />
      </>
    );
  };

  const ErrorCodeCell = (row: any): JSX.Element => {
    const errorColor = '#d32f2f';
    const userErrorCode: string = row.errorCode;
    let errorMessage: string;

    switch (userErrorCode) {
      case TransactionInErrorType.INVALID_START_DATE:
        errorMessage = translate(
          TransactionInErrorTypeDetail[
            TransactionInErrorType.INVALID_START_DATE
          ].title
        );
        break;
      case TransactionInErrorType.LONG_INACTIVITY:
        errorMessage = translate(
          TransactionInErrorTypeDetail[TransactionInErrorType.LONG_INACTIVITY]
            .title
        );
        break;
      case TransactionInErrorType.LOW_CONSUMPTION:
        errorMessage = translate(
          TransactionInErrorTypeDetail[TransactionInErrorType.LOW_CONSUMPTION]
            .title
        );
        break;
      case TransactionInErrorType.LOW_DURATION:
        errorMessage = translate(
          TransactionInErrorTypeDetail[TransactionInErrorType.LOW_DURATION]
            .title
        );
        break;
      case TransactionInErrorType.MISSING_PRICE:
        errorMessage = translate(
          TransactionInErrorTypeDetail[TransactionInErrorType.MISSING_PRICE]
            .title
        );
        break;
      case TransactionInErrorType.MISSING_USER:
        errorMessage = translate(
          TransactionInErrorTypeDetail[TransactionInErrorType.MISSING_USER]
            .title
        );
        break;
      case TransactionInErrorType.NEGATIVE_DURATION:
        errorMessage = translate(
          TransactionInErrorTypeDetail[TransactionInErrorType.NEGATIVE_DURATION]
            .title
        );
        break;
      case TransactionInErrorType.NEGATIVE_INACTIVITY:
        errorMessage = translate(
          TransactionInErrorTypeDetail[
            TransactionInErrorType.NEGATIVE_INACTIVITY
          ].title
        );
        break;
      case TransactionInErrorType.NO_BILLING_DATA:
        errorMessage = translate(
          TransactionInErrorTypeDetail[TransactionInErrorType.NO_BILLING_DATA]
            .title
        );
        break;
      case TransactionInErrorType.NO_CONSUMPTION:
        errorMessage = translate(
          TransactionInErrorTypeDetail[TransactionInErrorType.NO_CONSUMPTION]
            .title
        );
        break;
      case TransactionInErrorType.OVER_CONSUMPTION:
        errorMessage = translate(
          TransactionInErrorTypeDetail[TransactionInErrorType.OVER_CONSUMPTION]
            .title
        );
        break;
      default:
        errorMessage = '';
    }
    return <p style={{ color: errorColor }}>{errorMessage}</p>;
  };

  const setSiteFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isSiteLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onFilterApplied = (searchText: string) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        search: searchText,
        isResetBtnDisabled:
          searchText === '' &&
          currentData.siteName === '' &&
          currentData.siteAreaName === '' &&
          currentData.chargingStationId === '' &&
          (state.connectorID == -1 || !state.connectorID) &&
          !state.userID &&
          state.errorTypes.length == 0 &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()),
      };
    });
  };

  const onClearSelectedSite = () => {
    isOnClickSiteFilterClear = true;
    siteLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteID: '',
        siteName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteAreaName &&
          !state.chargingStationId &&
          (state.connectorID == -1 || !state.connectorID) &&
          !state.userID &&
          state.errorTypes.length == 0 &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()),
      };
    });
  };

  const setSiteAreaFilter = () => {
    if (!isOnClickSiteAreaFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isSiteAreaLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteAreaFilterClear = false;
  };

  const onClearSelectedSiteArea = () => {
    isOnClickSiteAreaFilterClear = true;
    siteAreaLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        siteAreaID: '',
        siteAreaName: '',
        isSiteAreaLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteName &&
          !state.chargingStationId &&
          (state.connectorID == -1 || !state.connectorID) &&
          !state.userID &&
          state.errorTypes.length == 0 &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()),
      };
    });
  };

  const resetSiteFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
      };
    });
  };

  const onSiteSelect = (sites: any) => {
    const siteName =
      sites?.length > 1
        ? `${sites[0].name} (+${sites?.length - 1})`
        : sites?.length && sites[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteLookupModalOpen: false,
        siteID: sites.map((site: any) => site.id).join('|'),
        siteName: siteName,
        isResetBtnDisabled: false,
      };
    });
  };

  const resetSiteAreaFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
      };
    });
  };

  const onSiteAreaSelect = (siteareas: any) => {
    const siteAreaName =
      siteareas?.length > 1
        ? `${siteareas[0].name} (+${siteareas?.length - 1})`
        : siteareas?.length && siteareas[0].name;
    setStateData((currentData) => {
      return {
        ...currentData,
        isSiteAreaLookupModalOpen: false,
        siteAreaID: siteareas.map((siteArea: any) => siteArea.id).join('|'),
        siteAreaName: siteAreaName,
        isResetBtnDisabled: false,
      };
    });
  };

  useEffect(() => {
    fetchTransactionsInError();
  }, [
    state.limit,
    state.page,
    state.sortFields,
    state.chargingStationId,
    state.errorTypes,
    state.search,
    state.siteID,
    state.siteAreaID,
    state.userID,
    state.connectorID,
    state.startDate,
    state.endDate,
  ]);

  const resetChargingPlanFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        chargingStationID: '',
        isChargingStationLookupModalOpen: false,
      };
    });
  };

  const onChargingStationSelect = (chargingStations: any) => {
    const chargingStationName =
      chargingStations?.length > 1
        ? `${chargingStations[0].id} (+${chargingStations.length - 1})`
        : chargingStations?.length && chargingStations[0].id;

    setStateData((currentData) => {
      return {
        ...currentData,
        isChargingStationLookupModalOpen: false,
        chargingStationId: chargingStations
          .map((chargingStation: any) => chargingStation.id)
          .join('|'),
        chargingStationName: chargingStationName,
        isResetBtnDisabled: false,
      };
    });
  };

  const onErrorSelect = (selectedItems: any[]) => {
    const updatedItems = selectedItems.map((item) => {
      return filterSelector[Filters.INERROR_ERROR].value[item];
    });
    setStateData((currentData) => {
      return {
        ...currentData,
        isResetBtnDisabled: false,
        errorTypes: updatedItems,
      };
    });
    if (selectedItems.length === 0) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isResetBtnDisabled:
            !state.search &&
            !state.chargingStationId &&
            !state.siteID &&
            !state.siteAreaID &&
            !state.userID &&
            (state.connectorID == -1 || !state.connectorID) &&
            (state.startDate.toString() == startDate.toISOString() ||
              state.endDate.toString() == endDate.toISOString()),
        };
      });
    }
  };

  const setChargingStationFilter = () => {
    if (!isOnClickSiteFilterClear) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isChargingStationLookupModalOpen: true,
        };
      });
    }
    isOnClickSiteFilterClear = false;
  };

  const onClearSelectedChargingStation = () => {
    isOnClickSiteFilterClear = true;
    chargingStationLookupRef?.current?.setFocus();
    setStateData((currentData) => {
      return {
        ...currentData,
        chargingStationId: '',
        chargingStationName: '',
        isSiteLookupModalOpen: false,
        isResetBtnDisabled:
          !state.search &&
          !state.siteID &&
          !state.userID &&
          !state.siteAreaID &&
          state.errorTypes.length == 0 &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()),
      };
    });
  };

  const setUserFilter = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showUserLookUpModal: true,
      };
    });
  };

  const onClearSelectedUser = (event: any) => {
    event.stopPropagation();
    setStateData((currentData) => {
      return {
        ...currentData,
        userID: '',
        userName: '',
        isResetBtnDisabled:
          !state.search &&
          !state.chargingStationId &&
          !state.siteID &&
          !state.siteAreaID &&
          state.errorTypes.length == 0 &&
          (state.connectorID == -1 || !state.connectorID) &&
          (state.startDate.toString() == startDate.toISOString() ||
            state.endDate.toString() == endDate.toISOString()),
      };
    });
  };

  const onUserFilterModalClose = () => {
    setStateData((currentData) => {
      return {
        ...currentData,
        showUserLookUpModal: false,
      };
    });
  };

  const onUserSelected = (users: any) => {
    const userName =
      users?.length > 1
        ? `${users[0].firstName} ${users[0].name} (+${users?.length - 1})`
        : users?.length && `${users[0].firstName} ${users[0].name}`;
    setStateData((currentDate) => {
      return {
        ...currentDate,
        userID: users.map((user: any) => user.id).join('|'),
        userName: userName,
        showUserLookUpModal: false,
        isResetBtnDisabled: false,
      };
    });
  };

  const onConnectorSelect = (connectorID: string[]) => {
    const filter: number[] = [];
    connectorID.forEach((item: string) => {
      filter.push(item[0].charCodeAt(0) - 64);
    });
    setStateData((currentData) => {
      return {
        ...currentData,
        connectorID: filter.join('|'),
        isResetBtnDisabled: false,
      };
    });
    if (connectorID.length === 0) {
      setStateData((currentData) => {
        return {
          ...currentData,
          isResetBtnDisabled:
            !state.search &&
            !state.siteAreaID &&
            !state.chargingStationId &&
            !state.siteID &&
            !state.siteAreaID &&
            !state.userID &&
            state.errorTypes.length == 0 &&
            (state.startDate.toString() == startDate.toISOString() ||
              state.endDate.toString() == endDate.toISOString()),
        };
      });
    }
  };

  const onSelectedRow = (data: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        selectedRecords: data,
      };
    });
  };

  const onClickDelete = () => {
    handleDeleteTransactions(
      state.selectedRecords,
      props,
      dataTableRef,
      fetchTransactionsInError,
      setStateData,
      translate
    );
  };

  const onDateRangeChange = (e: any) => {
    setStateData((currentData) => {
      return {
        ...currentData,
        startDate: e[0].toISOString(),
        endDate: e[1].toISOString(),
        isResetBtnDisabled: false,
      };
    });
  };

  const alignStyle: CSSProperties = { alignItems: 'flex-end !important' };

  return (
    <>
      {state.showUserLookUpModal && (
        <LookupComponent
          title={`${translate('users.select_users')}`}
          showLookupModal={state.showUserLookUpModal}
          onModalClose={onUserFilterModalClose}
          selectionMode='multiple'
          entityType={LookupEntityType.SESSION_USER}
          params={[{ key: 'Issuer', value: true }]}
          onSelect={onUserSelected}
          defaultSortColumn='name'
          data-cy='session-select-user-lookup'
        />
      )}

      <Content
        style={{ overflow: 'auto' }}
        contentTop={
          <Grid>
            <Grid
              container
              item
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Grid item xs={8} lg={8} md={8} sm={6} xl={9}>
                {showfilter && (
                  <Grid
                    container
                    item
                    spacing={2}
                    rowSpacing={1}
                    sx={alignStyle}
                  >
                    {isOrganizationActive && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <DatetimeRangePicker
                          isDefaultRangeShortcut={true}
                          defaultStartDate={startDate}
                          defaultEndDate={endDate}
                          startDate={state.startDate}
                          endDate={state.endDate}
                          cleanable={false}
                          onDateRangeChange={onDateRangeChange}
                        />
                      </Grid>
                    )}
                    {isOrganizationActive && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <FilterSelector
                          filterOption={Filters.INERROR_ERROR}
                          isResetBtnDisabled={state.isResetBtnDisabled}
                          onChange={onErrorSelect}
                        />
                      </Grid>
                    )}
                    {isOrganizationActive && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <EvesTextbox
                          value={state.siteName}
                          id='session-inError-standard-search'
                          label={`${translate('general.search_site')}`}
                          data-cy='select-site'
                          type='text'
                          variant='standard'
                          onClick={setSiteFilter}
                          onChange={onFilterApplied}
                          ref={siteLookupRef}
                          isFilter={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedSite}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    {isUserCanListUsers && (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <EvesTextbox
                          value={state.siteAreaName}
                          id='session-inError-standard-search'
                          label={`${translate('site_areas.titles')}`}
                          data-cy='select-site-area'
                          type='text'
                          variant='standard'
                          onClick={setSiteAreaFilter}
                          onChange={onFilterApplied}
                          isFilter={true}
                          ref={siteAreaLookupRef}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedSiteArea}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    )}
                    {
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <EvesTextbox
                          value={state.chargingStationName}
                          style={{ width: '100%', cursor: 'pointer' }}
                          id='inError-charging-station-lookup'
                          label={`${translate('chargers.chargers')}`}
                          type='text'
                          variant='standard'
                          data-cy='inError-charging-station-lookup'
                          onClick={setChargingStationFilter}
                          onChange={onFilterApplied}
                          ref={chargingStationLookupRef}
                          isFilter={true}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EvesIconButton
                                  icon={Close}
                                  fontSize={15}
                                  onClick={onClearSelectedChargingStation}
                                ></EvesIconButton>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    }

                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <FilterSelector
                        filterOption={Filters.CONNECTORS}
                        defaultIssuer={state.defaultIssuer}
                        onChange={onConnectorSelect}
                        isResetBtnDisabled={state.isResetBtnDisabled}
                        data-cy='connector-filter'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <EvesTextbox
                        value={state.userName}
                        id='User-standard-search'
                        label={`${translate('sites.users')}`}
                        data-cy='User-filter'
                        type='text'
                        variant='standard'
                        onClick={setUserFilter}
                        onChange={onFilterApplied}
                        isFilter={true}
                        ref={UserLookupRef}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <EvesIconButton
                                icon={Close}
                                fontSize={15}
                                onClick={onClearSelectedUser}
                              ></EvesIconButton>
                            </InputAdornment>
                          ),
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      data-cy='search-field'
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={3}
                    >
                      <SearchBox
                        ref={searchBoxRef}
                        onChange={onFilterApplied}
                        onRemoveClick={fetchTransactionsInError}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                gap={'5px'}
                mt={1}
                xs={4}
                sm={6}
                lg={4}
                xl={3}
                md={4}
                sx={{ flexWrap: 'wrap' }}
              >
                {/* <Grid item display={'flex'} justifyContent={'end'} gap={'10px'}>
                  <Tooltip
                    title={`${translate(showfilter ? 'Close' : 'Filter')}`}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'common.white',
                          color: 'black',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                        },
                      },
                    }}
                    followCursor={false}
                    placement={'bottom-start'}
                    enterNextDelay={500}
                  >
                   <Grid item>
                              {!showfilter ? (
                                
                                 <EvesButton
                                 
                                    onClick={() => {
                                       setShowfilter(!showfilter);
                                    }}
                                    data-cy='show-filter'
                                 >
                                    <img src={sessionfilter} />
                                 </EvesButton>
                                 
                              ) : (
                                 <EvesButton
                                    sx={{                                       
                                       border: '1px solid #3D3AD3 !important',
                                       background:
                                          'rgba(61, 58, 211, 0.10) !important',
                                       '&:hover': {
                                          background:
                                             'rgba(61, 58, 211, 0.10) !important',
                                       },
                                    }}
                                    onClick={() => {
                                       setShowfilter(!showfilter);
                                       onClickresetFilters();
                                    }}
                                    data-cy='close-filter'
                                 >
                                    <CloseIcon style={{ color: '3D3AD3' }} />
                                 </EvesButton>
                                
                              ) }</Grid>
                  </Tooltip>
                  <Tooltip
                    title={`${translate('Export')}`}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'common.white',
                          color: 'black',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                        },
                      },
                    }}
                    followCursor={false}
                    placement={'bottom-start'}
                    enterNextDelay={500}
                  >
                    <Grid item>
                      <EvesButton
                        sx={{ height: '40px', width: '50px' }}
                        data-cy="exportbtn"
                        // onClick={onClickExportNewCharging}
                      >
                        <CloudDownload />
                      </EvesButton>
                    </Grid>
                  </Tooltip>

                  
                      <TableRefreshComponent
                        onRefresh={fetchTransactionsInError}
                        componentName="transcationInError"
                        autoRefreshStatus={false}
                      />
                  
                </Grid>
                <Grid item display={'flex'} justifyContent={'end'} gap={'10px'}>
                  <Grid item display={'flex'} mt={2} gap={'16px'}>
                    {!state.isResetBtnDisabled && (
                      <Tooltip
                        title={`${translate('general.reset_filter')}`}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: 'common.white',
                              color: 'black',
                              borderStyle: 'solid',
                              borderWidth: '1px',
                            },
                          },
                        }}
                        followCursor={false}
                        placement={'bottom-start'}
                        enterNextDelay={500}
                      >
                        <Grid item>
                          <EvesButton
                            sx={{ height: '40px' }}
                            onClick={onClickresetFilters}
                            data-cy="transactionsInProgress-reset-filter-button"
                          >
                            <Cancel />
                          </EvesButton>
                        </Grid>
                      </Tooltip>
                    )}

                    {state.selectedRecords.length > 0 && (
                      <Grid item>
                        <Tooltip
                          title={`${translate('Delete')}`}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: 'common.white',
                                color: 'black',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                              },
                            },
                          }}
                          followCursor={false}
                          placement={'bottom-start'}
                          enterNextDelay={500}
                        >
                          <EvesButton
                            sx={{
                              height: '40px',
                              width: '50px',
                              background: '#D3302F !important',

                              '&:hover': {
                                background: '#D3302F !important',
                              },
                            }}
                            onClick={onClickDelete}
                            data-cy="create-station"
                          >
                            <img src={Delete} alt="img" />
                          </EvesButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid> */}
                <ButtonGroup
                  {...{
                    showfilter,
                    setShowfilter,
                    resetFilters: onClickresetFilters,
                    state,
                    onClickDelete: onClickDelete,
                    isExport: true,
                    isDelete: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        contentBody={
          <DataTable
            ref={dataTableRef}
            className='transaction-in-error-table'
            selectionMode='multiple'
            rows={state.rows}
            columns={buildTableColumns()}
            totalRowsCount={state.totalRowsCount}
            actionsMenu={primaryActions}
            contextActionsMenu={secondaryActions}
            onRowSelectionChange={onSelectedRow}
            defaultSortField={defaultSortColumn}
            defaultSortDescending={true}
            projectFields={state.projectFields}
            data-cy='transaction-in-error-table'
            onChange={onTableChange}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            count={state.totalRowsCount}
            limit={state.limit}
            page={state.page}
            tablePagination={true}
          >
            <ErrorCodeDetailsCell slot='errorDetail' />
            <ErrorCodeCell slot='errorCode' />
          </DataTable>
        }
      ></Content>
      {state.isErrorDetailModalOpen && (
        <ErrorDetailModal
          isErrorDetailModalOpen={state.isErrorDetailModalOpen}
          onClose={OnErrorDetailModalClose}
          errorCode={state.transactionErrorCode}
          errorTypeDetailConstant={TransactionInErrorTypeDetail}
        />
      )}

      {state.isChargingStationLookupModalOpen && (
        <LookupComponent
          data-cy='sessions-charging-plan-lookup'
          title={`${translate('chargers.select_chargers')}`}
          showLookupModal={state.isChargingStationLookupModalOpen}
          onModalClose={resetChargingPlanFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.CHARGINGSTATION}
          params={
            state.siteAreaID
              ? [
                  { key: 'Issuer', value: state.issuer },
                  { key: 'SiteAreaID', value: state.siteAreaID },
                ]
              : [{ key: 'Issuer', value: state.issuer }]
          }
          onSelect={onChargingStationSelect}
          defaultSortColumn='id'
        />
      )}
      {state.isSiteLookupModalOpen && (
        <LookupComponent
          title={`${translate('sites.select_sites')}`}
          showLookupModal={state.isSiteLookupModalOpen}
          onModalClose={resetSiteFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SITE}
          params={[{ key: 'Issuer', value: state.issuer }]}
          onSelect={onSiteSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isSiteAreaLookupModalOpen && (
        <LookupComponent
          title={`${translate('site_areas.select_site_areas')}`}
          showLookupModal={state.isSiteAreaLookupModalOpen}
          onModalClose={resetSiteAreaFilter}
          selectionMode='multiple'
          entityType={LookupEntityType.SITEAREA}
          params={[
            { key: 'Issuer', value: state.issuer },
            { key: 'SiteID', value: state.siteID || null },
            { key: 'WithSite', value: true },
          ]}
          onSelect={onSiteAreaSelect}
          defaultSortColumn='name'
        />
      )}
      {state.isTransactionDetailModalOpen && (
        <TransactionDetails
          transactionId={state.selectedTransactionId}
          chargingStationId={state.selectedChargingStationID}
          connectorId={state.selectedConnectorID}
          onClose={onTransactionDetailModalClose}
        />
      )}
    </>
  );
};

export default withContext(TrasactionsInError);
